/* tslint:disable:triple-equals */
 
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subject, interval } from 'rxjs';
import { take, retry, switchMap } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '@environment';
import { JsonApiQueryData, JsonApiModel } from '@elearnio/angular2-jsonapi';

import { Datastore } from '@services/datastore';
import { UserService } from '@services/user.service';
import { AuthService } from '@services/auth.service';
import { Member } from '@models/member.model';
import { User } from '@models/user.model';
import { Roles } from '@core/constants/roles.constant';
import { ApiService } from './api.service';
import { GtmWrapperService } from '@services/gtm-wrapper.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private member: Member | null = null;
  private isLoading = false;
  private concurrentRequestSubject = new Subject<Member | null>();
  private pollingInterval = 60;
  private userId = '';

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private datastore: Datastore,
    private gtmService: GtmWrapperService,
  ) {
    this.authService.registerLogoutCallback(() => this.clearMemberOnLogout());
  }

  public current(): Observable<Member | null> {
    const subject = new ReplaySubject<Member | null>(1);

    if (this.isLoading) {
      return this.concurrentRequestSubject.asObservable();
    }

    if (!this.member) {
      this.userService
        .getCurrentUser()
        .pipe(take(2))
        .subscribe((user: User | null) => {
          if (user === null) {
            return;
          }
          this.isLoading = true;
          this.userId = user.id;

          this.getCurrentMember()
            .pipe(take(1))
            .subscribe((member: Member) => {
              this.member = member;
              this.isLoading = false;
              this.concurrentRequestSubject.next(this.member);
              this.gtmService.pushTag({
                language: this.member.language,
                 
                user_role: this.member.role,
              });
              subject.next(this.member);
            });
        });
    } else {
      subject.next(this.member);
    }

    return subject;
  }

  public pollCurrent(): Observable<Member | null> {
    const subject = new ReplaySubject<Member | null>(1);
    const subscription = interval(this.pollingInterval * 1000)
      .pipe(
        retry(10),
        switchMap(() => this.getCurrentMember()),
      )
      .pipe(untilDestroyed(this))
      .subscribe(member => {
        this.member = member;
        this.gtmService.pushTag({
          language: this.member.language,
           
          user_role: this.member.role,
        });

        subject.next(member);
      });

    this.authService.registerLogoutCallback(() => {
      subscription.unsubscribe();
      this.member = null;
    });
    return subject;
  }

  public isMemberAdmin(currentMember: Member | undefined): boolean {
    return currentMember?.role === Roles.ADMIN;
  }

  public isMemberGlobalCoach(currentMember: Member | undefined): boolean {
    return currentMember?.role === Roles.GLOBAL_COACH;
  }

  public getMemberByIds(
    ids: string[],
  ): Observable<JsonApiQueryData<JsonApiModel>> {
    const subject = new Subject<JsonApiQueryData<JsonApiModel>>();

    this.api
      .post(environment.backendBaseUrl, '/api/v1/byids/members', {
         
        member_ids: ids,
      })
      .pipe(take(1))
      .subscribe(response => {
        const members = response.body.data?.map((member: any) => {
          const deserializedMember = this.datastore.deserializeModel(
            Member,
            member,
          ) as Member;
          deserializedMember.id = deserializedMember.id?.toString();
          return deserializedMember;
        });

        subject.next(new JsonApiQueryData<Member>(members));
      });

    return subject.asObservable();
  }

  private clearMemberOnLogout(): void {
    this.member = null;
  }

  private getCurrentMember(): Observable<Member> {
    return this.datastore.findRecord(Member, this.userId, {
      include: 'gamification,organisation_levels',
    });
  }
}
