import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { AppToolbarFiltersComponent } from './app-toolbar-filters.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MultiSelectServerSideSearchModule } from '@shared/multi-select-server-side-search/multi-select-server-side-search.module';
import { MultiSelectLocalSearchModule } from '@shared/multi-select-local-search/multi-select-local-search.module';
import { ElearnioLanguageSpanModule } from '@app/shared/elearnio-language-icon/elearnio-language-span.module';

@NgModule({
  declarations: [AppToolbarFiltersComponent],
  exports: [AppToolbarFiltersComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatBadgeModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    MatSlideToggleModule,
    MultiSelectServerSideSearchModule,
    MultiSelectLocalSearchModule,
    ElearnioLanguageSpanModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
})
export class AppToolbarFiltersModule {}
