import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '@services/api.service';
import { SnackBarService } from './snack-bar.service';
import { CompleteCourseData } from '@core/interfaces/course.interface';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private saveCourseSubject = new Subject<string>();
  private courseProgresses: { [key: string]: number } = {};
  private isEditor = new BehaviorSubject<boolean>(false);

  constructor(
    private api: ApiService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {}

  public setCourseProgress(courseId: string, progress: number): void {
    this.courseProgresses[courseId] = progress;
  }

  public getCourseProgress(courseId: string): number {
    return this.courseProgresses[courseId] || 0;
  }

  public setIsEditor(isEditor: boolean): void {
    this.isEditor.next(isEditor);
  }

  public getIsEditor(): Observable<boolean> {
    return this.isEditor.asObservable();
  }

  public saveCourse(path: string): void {
    this.saveCourseSubject.next(path);
  }

  public courseSaveListener(): Observable<string> {
    return this.saveCourseSubject.asObservable();
  }

  public sortByOrder<T extends { order?: number }>(elements: T[]): T[] {
    return elements.sort((a, b) => {
      if (a.order == null) {
        return 1;
      } else if (b.order == null) {
        return -1;
      }
      if (a.order === b.order) {
        return 0;
      }
      return a.order > b.order ? 1 : -1;
    });
  }

  public duplicateCourse(courseId: string): Observable<void> {
    const subject = new Subject<void>();
    this.api
      .post(environment.backendBaseUrl, '/api/v1/courses/duplicate', {
         
        course_id: courseId,
      })
      .pipe(take(1))
      .subscribe(() => {
        this.snackBarService.success(
          this.translateService.instant('courses.duplicateCourse'),
        );
        subject.next();
      });

    return subject.asObservable();
  }

  public exportCourse(courseId: string): void {
    this.api
      .get(environment.backendBaseUrl, `/api/v1/courses/${courseId}/export`)
      .pipe(take(1))
      .subscribe(() => {
        this.snackBarService.success(
          this.translateService.instant('courses.exportCourseMsg'),
        );
      });
  }

  public getImportCourseUrl(filename: string): Observable<string> {
    const subject = new Subject<string>();
    this.api
      .post(environment.backendBaseUrl, '/api/v1/course/import/uploadurl', {
        filename: encodeURIComponent(filename),
      })
      .pipe(take(1))
      .subscribe(
        response => {
          subject.next(response.body['upload_to']);
        },
        () => {
          subject.next('');
        },
      );
    return subject.asObservable();
  }

  public uploadFile(file: File, url: string): void {
    this.api
      .put('', url, file, {
        responseType: 'text',
      })
      .pipe(take(1))
      .subscribe(
        response => {
          this.importCourse(this.getCourseS3Key(response.url));
        },
        () => {},
      );
  }

  public saveCourseResult(data: CompleteCourseData): Observable<void> {
    const subject = new Subject<void>();
    this.api
      .post(environment.backendBaseUrl, '/api/v1/courses/manual_result', {
         
        course_id: data.courseId,
         
        member_data: data.memberData,
        grading: data.grading,
         
        completion_date: data.completionDate,
         
        courses_member_repetition_id: data.repetitionId,
      })
      .pipe(take(1))
      .subscribe(() => {
        this.snackBarService.success(
          this.translateService.instant('courses.completeCourseSuccess'),
        );
        subject.next();
      });

    return subject.asObservable();
  }

  private getCourseS3Key(url: string): string {
    const urlSeparator = '/';
    const pathName = decodeURI(new URL(url).pathname).split(urlSeparator);
    pathName.splice(0, 2);
    return pathName.join(urlSeparator);
  }

  private importCourse(s3Key: string): void {
    this.api
      .post(environment.backendBaseUrl, '/api/v1/course/import', {
         
        s3_key: s3Key,
      })
      .pipe(take(1))
      .subscribe(() => {
        this.snackBarService.success(
          this.translateService.instant('courses.importCourseMsg'),
        );
      });
  }
}
