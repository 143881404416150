import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-training-status',
  templateUrl: './training-status.component.html',
  styleUrls: ['./training-status.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class TrainingStatusComponent {
  // TODO Move all components using this to app-status-text.
  @Input() type = '';
  @Input() isNew = false;
  @Input() isNewText = '';
  @Input() isInProgress = false;
  @Input() isInProgressText = '';
  @Input() isFinished = false;
  @Input() isFinishedText = '';
  @Input() isFailed = false;

  constructor() {}
}
