import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-h5p-player-footer',
  templateUrl: './h5p-player-footer.component.html',
  styleUrls: ['./h5p-player-footer.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class H5PPlayerFooterComponent implements OnInit {
  @Input() hasNext = false;
  @Input() hasPrevious = false;
  @Input() isLastActivity = false;
  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onNext(): void {
    this.next.emit();
  }

  onPrevious(): void {
    this.previous.emit();
  }
}
