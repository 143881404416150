import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@services/auth.service';
import { Member } from '@models/member.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Gamification } from '@models/gamification/gamification.model';

import { Roles } from '@core/constants/roles.constant';
import { MediaMatcher } from '@angular/cdk/layout';
import { DrawerService } from '@services/drawer.service';
import { NotificationCenterService } from '@services/notification-center.service';
import { Router } from '@angular/router';
import { NO_REPORTING_CUSTOMERS } from '@core/constants/no-reporting-customers.constants';
import { PermissionService } from '@app/core/services/permission.service';
import { MatTableDataSource } from '@angular/material/table';
import { MemberNotification } from '@app/models/member/member-notification.model';
import { NotificationCenterComponent } from '../notification-center/notification-center.component';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() isDefaultBrandColor = true;
  @Input() logo = '';
  @Input() showGamificationBadge = false;
  @Input() currentMember: Member | undefined;
  @ViewChild('notificationCenter') notificationCenterComponent:
    | NotificationCenterComponent
    | undefined;

  params: any = { start: 0, end: 0, interval: 0 };
  mobileQuery: MediaQueryList;
  isOpened = false;
  isAdminOrCoach = false;
  showReporting = false;
  isNotificationOpen = false;
  notificationAlert = false;
  dataSource: MatTableDataSource<MemberNotification>;
  columnsToDisplay = ['operation', 'owner', 'timeStarted', 'status', 'actions'];
  private readonly mobileQueryListener: () => void;
  private userNotifications: MemberNotification[] = [];

  constructor(
    private authService: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private drawerService: DrawerService,
    public router: Router,
    private translateService: TranslateService,
    private permissionService: PermissionService,
    private notificationCenter: NotificationCenterService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 959px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    this.drawerService.toggle
      .pipe(untilDestroyed(this))
      .subscribe(toggled => (this.isOpened = toggled));

    this.dataSource = new MatTableDataSource(this.userNotifications);
    this.notificationCenter
      .notifyListener()
      .pipe(untilDestroyed(this))
      .subscribe(notify => {
        this.notificationAlert = notify;
      });
  }

  ngOnInit(): void {
    this.setShowReporting();
  }

  ngOnChanges(): void {
    this.isAdminOrCoach =
      this.currentMember?.role === Roles.ADMIN ||
      this.currentMember?.role === Roles.GLOBAL_COACH;
    this.setGamificationData(this.currentMember?.gamification);
  }

  public signOut(event: MouseEvent | KeyboardEvent): void {
    event.preventDefault();

    this.authService.logout();
  }

  toggleDrawer(): void {
    this.drawerService.toggle.next(!this.isOpened);
  }

  openHelpCenter(): void {
    const url = this.translateService.instant('main.header.helpCenterUrl');
    window.open(url, '_blank');
  }

  openWhatsNew(): void {
    const url = this.translateService.instant('main.header.whatsNewUrl');
    window.open(url, '_blank');
  }

  openUserback(): void {
     
    // @ts-ignore
    if (!Userback) {
      return;
    }

     
    // @ts-ignore
    Userback.open();
  }

  openRoadmap(): void {
    const url = this.translateService.instant('main.header.roadmapUrl');
    window.open(url, '_blank');
  }

  getPermissionsService(): PermissionService {
    return this.permissionService;
  }

  getAssignPermission(): boolean {
    if (this.currentMember?.role === Roles.AUTHOR) {
      return (
        this.getPermissionsService().getOrganizationPermissions()
          .assign_training || false
      );
    }

    return (
      this.getPermissionsService().getOrganizationPermissions()
        .assign_all_entities || false
    );
  }

  getAdminPermission(): boolean {
    return (
      this.getPermissionsService().getOrganizationPermissions()
        .admin_permission || false
    );
  }

  getPeopleManagementPermission(): boolean {
    return (
      this.getPermissionsService().getOrganizationPermissions()
        .people_management || false
    );
  }

  getReportPermission(): boolean {
    return (
      this.getPermissionsService().getOrganizationPermissions().reporting ||
      false
    );
  }

  menuOpened(): void {
    this.isNotificationOpen = true;
    this.notificationCenterComponent?.onRefresh();
  }

  menuClosed(): void {
    this.isNotificationOpen = false;
  }

  private setShowReporting(): void {
    this.showReporting =
      NO_REPORTING_CUSTOMERS.indexOf(window.location.hostname) === -1;
  }

  private setGamificationData(gamification: Gamification | undefined) {
    if (!gamification) {
      return;
    }

    this.params = {
      start: this.params.end,
      end: gamification?.score || 0,
      interval: 1,
    };
  }
}
