import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { take } from 'rxjs/operators';

import { formatFileSizeUnits } from '@helper/file-helper';
import { CourseService } from '@app/core/services/course.service';

@Component({
  selector: 'app-import-course-dialog',
  templateUrl: './import-course-dialog.component.html',
  styleUrls: ['./import-course-dialog.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class ImportCourseDialogComponent implements OnInit {
  courseZipFile: File | undefined;

  constructor(
    public dialogRef: MatDialogRef<ImportCourseDialogComponent>,
    public courseService: CourseService,
  ) {}

  ngOnInit(): void {}

  onZipFileChange(event: NgxDropzoneChangeEvent): void {
    if (event.addedFiles[0]) {
      this.courseZipFile = event.addedFiles[0];
    }
  }

  formatFileSize(size: number): string {
    return `(${formatFileSizeUnits(size)})`;
  }

  importCourse(): void {
    if (this.courseZipFile) {
      this.courseService
        .getImportCourseUrl(this.courseZipFile.name)
        .pipe(take(1))
        .subscribe((url: string) => {
          if (this.courseZipFile) {
            this.courseService.uploadFile(this.courseZipFile, url);
          }
        });
    }
  }
}
