import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoBubbleType } from '@app/shared/info-bubble';

@Component({
  selector: 'app-h5p-player-timesup-dialog',
  templateUrl: './h5p-player-timesup-dialog.component.html',
  styleUrls: ['./h5p-player-timesup-dialog.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class H5PPlayerTimesUpDialogComponent implements OnInit {
  InfoBubbleType = InfoBubbleType;
  quizTimeUp = '/assets/images/quiz-time-up.png';

   
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  public ngOnInit(): void {}
}
