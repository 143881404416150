import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { EditCurrentPasswordDialogComponent } from './edit-current-password-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

export const declarations = [EditCurrentPasswordDialogComponent];

export const imports = [
  ReactiveFormsModule,
  FormsModule,
  FlexLayoutModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
];

export const providers = [];

@NgModule({
  declarations,
  imports: [CommonModule, TranslateModule, ...imports],
  exports: declarations,
  providers,
})
export class EditCurrentPasswordDialogModule {}
