import { Injectable } from '@angular/core';
import { Datastore } from '@services/datastore';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CourseMemberAnswer } from '@models/course/member/course-member-answer.model';
import { JsonApiQueryData } from '@elearnio/angular2-jsonapi';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CourseMemberAnswerService {
  private filter: Record<string, unknown> = {};

  constructor(private datastore: Datastore) {}

  public select(): CourseMemberAnswerService {
    this.filter = {};

    return this;
  }

  public filterByActivities(
    courseActivityIds: string[],
  ): CourseMemberAnswerService {
     
    this.filter.course_activity_ids = courseActivityIds;

    return this;
  }

  public filterByCourseAndMember(
    courseId: string,
    memberId: string,
  ): CourseMemberAnswerService {
     
    this.filter.latest_for_course_and_member = [courseId, memberId];

    return this;
  }

  public filterByCourseRepetitionAndMember(
    courseId: string,
    memberId: string,
    repetitionDate: string,
  ): CourseMemberAnswerService {
    if (repetitionDate) {
       
      this.filter.latest_for_course_repetition_and_member = [
        courseId,
        memberId,
        repetitionDate,
      ];

      return this;
    } else {
      return this.filterByCourseAndMember(courseId, memberId);
    }
  }

  public request(): Observable<JsonApiQueryData<CourseMemberAnswer>> {
    return this.datastore.findAll(CourseMemberAnswer, {
      filter: this.filter,
    });
  }
}
