import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { TrainingNameWithStateComponent } from './training-name-with-state.component';
import { ElearnioMatTableLinkModule } from '../elearnio-mat-table-link/elearnio-mat-table-link.module';

export const imports = [
  CommonModule,
  RouterModule,
  FlexLayoutModule,
  TranslateModule,
  MatIconModule,
  ElearnioMatTableLinkModule,
];

export const declarations = [TrainingNameWithStateComponent];

@NgModule({
  declarations,
  exports: [TrainingNameWithStateComponent],
  imports,
  providers: [],
})
export class TrainingNameWithStateModule {}
