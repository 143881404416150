import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DrawerService } from '@services/drawer.service';
import { AuthService } from '@services/auth.service';
import { PermissionService } from '@app/core/services/permission.service';
import { FeatureFlagService } from '@app/core/services/feature-flag.service';
import { take } from 'rxjs';
import { FEATURES } from '@core/constants/features.constant';

@Component({
  selector: 'app-navigation-sidebar',
  templateUrl: './navigation-sidebar.component.html',
  styleUrls: ['./navigation-sidebar.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class NavigationSidebarComponent implements OnInit {
  @Input() isAdmin = false;
  @Input() showGamificationBadge = false;
  hasTrainingScheduleFeature = false;

  constructor(
    public router: Router,
    private drawerService: DrawerService,
    private authService: AuthService,
    private translateService: TranslateService,
    private permissionService: PermissionService,
    private featureFlagService: FeatureFlagService,
  ) {}

  get hasAssignedCohort(): boolean {
    return !!this.permissionService.getMemberPermissions()?.assigned_cohort;
  }

  ngOnInit(): void {
    this.featureFlagService
      .isFeatureFlagEnabled(FEATURES.TRAINING_SCHEDULE)
      .pipe(take(1))
      .subscribe(isEnabled => {
        this.hasTrainingScheduleFeature = isEnabled;
      });
  }

  closeSidebar(): void {
    this.drawerService.toggle.next(false);
  }

  logout(): void {
    this.closeSidebar();
    this.authService.logout();
  }

  openHelpCenter(): void {
    const url = this.translateService.instant('main.header.helpCenterUrl');
    window.open(url, '_blank');
  }

  openUserback(): void {
     
    // @ts-ignore
    if (!Userback) {
      return;
    }

    this.closeSidebar();
     
    // @ts-ignore
    Userback.open();
  }

  openRoadmap(): void {
    const url = this.translateService.instant('main.header.roadmapUrl');
    window.open(url, '_blank');
  }

  hasInstructorViewPermission(): boolean {
    return !!this.permissionService.getMemberPermissions()?.instructor;
  }
}
