import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatIconModule],
})
export class IconsModule {
  constructor(
    private matIconReg: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    matIconReg.addSvgIcon(
      'medal',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/medal.svg'),
    );
    matIconReg.addSvgIcon(
      'solid-medal',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/award-solid.svg'),
    );
    matIconReg.addSvgIcon(
      'live_training_teacher',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/live_training_teacher.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'books',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/books.svg'),
    );
    matIconReg.addSvgIcon(
      'certificate',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/certificate.svg'),
    );
    matIconReg.addSvgIcon(
      'course_add',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/course_add.svg'),
    );
    matIconReg.addSvgIcon(
      'highfive',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/highfive.svg'),
    );
    matIconReg.addSvgIcon(
      'live_training_group',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/live_training_group.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_0',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/0.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_1',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/1.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_2',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/2.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_3',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/3.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_4',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/4.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_5',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/5.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_6',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/6.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_7',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/7.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_8',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/8.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'orga_level_9',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/orga-icons/9.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'event_seat_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/ilt-course/event_seat_outline.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'globe_location_pin',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/ilt-course/globe_location_pin.svg',
      ),
    );
    matIconReg.addSvgIcon(
      'task_activity',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/content-types/task.svg',
      ),
    );
  }
}
