import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationSidebarComponent } from './navigation-sidebar.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

export const modules = [
  CommonModule,
  FlexLayoutModule,
  RouterModule,
  MatBadgeModule,
  MatIconModule,
  TranslateModule,
];

@NgModule({
  declarations: [NavigationSidebarComponent],
  imports: [modules],
  exports: [NavigationSidebarComponent],
})
export class NavigationSidebarModule {}
