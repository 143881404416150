import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { ElearnioMatTableLinkComponent } from './elearnio-mat-table-link.component';

export const imports = [
  CommonModule,
  RouterModule,
  FlexLayoutModule,
  TranslateModule,
  MatIconModule,
  MatTooltipModule,
];

export const declarations = [ElearnioMatTableLinkComponent];

@NgModule({
  declarations,
  exports: [ElearnioMatTableLinkComponent],
  imports,
  providers: [],
})
export class ElearnioMatTableLinkModule {}
