import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationHelper {
  private static translateService: TranslateService;

  static init(translate: TranslateService): void {
    TranslationHelper.translateService = translate;
  }

  static instant(key: string, params?: any): string {
    return TranslationHelper.translateService.instant(key, params);
  }
}
