import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-notification-modal-dialog',
  templateUrl: './notification.component.html',
  styleUrls: [
    './notification.component.scss',
    './notification-types.component.scss',
    './notification-close-modal.component.scss',
  ],
  // eslint-disable-next-line
  standalone: false,
})
export class NotificationComponent implements OnInit {
  constructor(public notify: NotificationService) {}

  ngOnInit(): void {}
}
