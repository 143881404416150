import { Component, Input } from '@angular/core';
import { TrainingAvailability } from '@app/core/constants/training-status.constants';

@Component({
  selector: 'app-training-name-with-state',
  templateUrl: './training-name-with-state.component.html',
  styleUrls: ['./training-name-with-state.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class TrainingNameWithStateComponent {
  @Input() text = '';
  @Input() url = '';
  @Input() state = TrainingAvailability.ACTIVE;

  TrainingAvailability = TrainingAvailability;

  constructor() {}
}
