import { Injectable } from '@angular/core';
import {
  MaterialCssVariables,
  MaterialCssVarsService,
} from 'angular-material-css-vars';
import { BrandPalette } from '@models/branding/brand-palette.model';

const DefaultColors = {
  brandColor: '57A7B5',
  interactiveElementsColor: '3D757F',
  mainButtonColor: 'FEC22F',
  buttonTextColor: '000000',
  whiteColor: 'FFFFFF',
};

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  private defaultBrandColor = true;

  constructor(private materialCssVarsService: MaterialCssVarsService) {
    this.setDefaultBrandColor();
  }

  public isDefaultBrandColor(): boolean {
    return this.defaultBrandColor;
  }

  public setDefaultBrandColor() {
    this.materialCssVarsService.setAutoContrastEnabled(false);
    this.materialCssVarsService.setVariable(
      MaterialCssVariables.Primary500,
      this.hexToRGB(DefaultColors.brandColor),
    );

    this.materialCssVarsService.setVariable(
      MaterialCssVariables.Accent500,
      this.hexToRGB(DefaultColors.interactiveElementsColor),
    );

    this.materialCssVarsService.setVariable(
      '--background-raised-button' as any,
      this.hexToRGB(DefaultColors.mainButtonColor),
    );

    this.materialCssVarsService.setVariable(
      MaterialCssVariables.Accent50,
      this.hexToRGB(DefaultColors.buttonTextColor),
    );
  }

  public setBrandColor(brandPalette: BrandPalette): void {
    this.defaultBrandColor =
      brandPalette?.brandColor.toLowerCase() ===
      DefaultColors.brandColor.toLowerCase();

    // disable auto contrast on individual chosen company color
    this.materialCssVarsService.setAutoContrastEnabled(false);
    this.materialCssVarsService.setPrimaryColor(brandPalette?.brandColor);

    this.materialCssVarsService.setAccentColor(
      '#' + brandPalette?.interactiveElementsColor,
    );

    this.materialCssVarsService.setVariable(
      '--background-raised-button' as any,
      this.hexToRGB(brandPalette?.mainButtonColor),
    );

    this.materialCssVarsService.setVariable(
      MaterialCssVariables.Accent50,
      this.hexToRGB(brandPalette?.buttonTextColor),
    );
  }

  private hexToRGB(hex: string): string {
    if (hex.length === 3) {
      hex = hex.replace(/.{1,1}/g, '$&$&');
    }

    const rgb = hex.match(/.{1,2}/g) as Array<string>;

    const rgbArray = [
      parseInt(rgb[0], 16),
      parseInt(rgb[1], 16),
      parseInt(rgb[2], 16),
    ];

    return rgbArray.join(', ');
  }
}
