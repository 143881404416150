/* tslint:disable:triple-equals */
 
import { Pipe, PipeTransform } from '@angular/core';
import { Course } from '@models/course.model';

export enum CourseProgressStatus {
  new = 'unassigned',
  assigned = 'assigned',
  running = 'running',
  finished = 'finished',
  subscribed = 'subscribed',
  completed = 'completed',
  failed = 'failed',
}

@Pipe({
  name: 'courseProgressStatus',
  // eslint-disable-next-line
  standalone: false,
})
export class CourseProgressStatusPipe implements PipeTransform {
  transform(course: Course | undefined): string {
    if (course?.courseResult) {
      if (course.courseResult.progress === 100) {
        return this.getResultStatus(course);
      }

      if (course.courseResult.progress > 0) {
        return CourseProgressStatus.running;
      }

      return CourseProgressStatus.subscribed;
    }

    return CourseProgressStatus.new;
  }

  transformStatusFromCode(statusCode: number): string {
    switch (statusCode) {
      case 0:
        return CourseProgressStatus.assigned;
      case 1:
        return CourseProgressStatus.running;
      case 2:
        return CourseProgressStatus.completed;
    }
    return CourseProgressStatus.assigned;
  }

  private getResultStatus(course: Course): string {
    return course.courseResult?.success
      ? CourseProgressStatus.finished
      : CourseProgressStatus.failed;
  }
}
