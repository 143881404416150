import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ImportCourseDialogComponent } from './import-course-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgxDropzoneModule } from 'ngx-dropzone';

export const declarations = [ImportCourseDialogComponent];

export const imports = [
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  FlexLayoutModule,
  NgxDropzoneModule,
];

@NgModule({
  declarations,
  imports: [CommonModule, RouterModule, TranslateModule, ...imports],
  exports: declarations,
})
export class ImportCourseDialogModule {}
