import { TranslationHelper } from '@core/helper/translation.helper';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../constants/days.constants';

export function getEventName(name: string, startDate: string): string {
  if (name) {
    return name;
  }

  return TranslationHelper.instant('common.eventNameCustom', {
    date: dayjs(startDate).format(DATE_FORMAT),
  });
}
