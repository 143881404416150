import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'course_reports',
})
export class CourseReport extends JsonApiModel {
  // Deprecated JSONApiModel. Serialized keys might not be synced with BE.
  // We use this in reporting where we do direct sql queries
  @Attribute({ serializedName: 'course_id' })
  courseId!: string;

  @Attribute({ serializedName: 'name' })
  courseName!: string;

  @Attribute({ serializedName: 'members_count' })
  membersCount!: number;

  @Attribute({ serializedName: 'members_running_count' })
  membersRunningCount!: number;

  @Attribute({ serializedName: 'members_completed_count' })
  coursesCompleted!: number;

  @Attribute({ serializedName: 'courses_missed' })
  coursesMissed!: number;

  @Attribute({ serializedName: 'course_progress_avg' })
  courseProgressAvg!: string;

  @Attribute({ serializedName: 'course_completion_avg' })
  courseCompletionAvg!: string;

  @Attribute({ serializedName: 'course_success_avg' })
  courseSuccessAvg!: string;

  @Attribute({ serializedName: 'course_score_avg' })
  courseScoreAvg!: string;

  @Attribute({ serializedName: 'time_spent_in_seconds_avg' })
  timeSpentInSecondsAvg!: number;

  @Attribute({ serializedName: 'course_rating_avg' })
  courseRatingAvg!: string;

  @Attribute({ serializedName: 'completion_level' })
  completionLevel!: string;

  @Attribute({ serializedName: 'estimated_time_in_minutes' })
  estimatedTimeInMinutes!: string;

  @Attribute({ serializedName: 'net_time_in_minutes' })
  netTimeInMinutes!: string;

  @Attribute({ serializedName: 'hasfeedback' })
  hasFeedback!: boolean;

  @Attribute({ serializedName: 'has_anonymous_feedback' })
  hasAnonymousFeedback!: boolean;

  @Attribute({ serializedName: 'repetition_interval_date' })
  repetitionIntervalDate!: string;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;
}
