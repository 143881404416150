import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoBubbleComponent } from './info-bubble.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    TranslateModule,
  ],
  declarations: [InfoBubbleComponent],
  exports: [InfoBubbleComponent],
})
export class InfoBubbleModule {}
