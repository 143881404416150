import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';
import { ILTCourse } from '@models/ilt-course.model';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'ilt_course_reminders',
})
export class ILTCourseReminder extends JsonApiModel {
  @Attribute()
  time!: number;

  @Attribute({ serializedName: 'time_unit' })
  timeUnit!: string;

  @Attribute({ serializedName: 'reminder_scope' })
  reminderScope!: string;

  @BelongsTo({ key: 'ilt_course' })
  iltCourse!: ILTCourse;
}
