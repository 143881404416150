export enum DAYS {
  MONDAY = '1',
  TUESDAY = '2',
  WEDNESDAY = '3',
  THURSDAY = '4',
  FRIDAY = '5',
  SATURDAY = '6',
  SUNDAY = '0',
}

export const DATE_FORMAT = 'DD.MM.YYYY';
