import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsSidebarComponent } from './settings-sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { TrainingStatusModule } from '@shared/training-status/training-status.module';

export const imports = [
  TranslateModule,
  RouterModule,
  FlexLayoutModule,
  TrainingStatusModule,
];

@NgModule({
  declarations: [SettingsSidebarComponent],
  imports: [CommonModule, MatIconModule, ...imports],
  exports: [SettingsSidebarComponent],
})
export class SettingsSidebarModule {}
