import { Injectable } from '@angular/core';
import { environment } from '@environment';

import {
  ScriptInjectorService,
  ScriptStore,
} from '@services/script-injector.service';
import { Member } from '@app/models/member.model';
import { ApiService } from './api.service';
import { take } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CelloService {
  constructor(
    private scriptService: ScriptInjectorService,
    private api: ApiService,
    private router: Router,
  ) {}

  load(member: Member): void {
    this.api
      .get(environment.backendBaseUrl, '/api/v1/member/cello_token')
      .pipe(take(1))
      .subscribe((response: HttpResponse<Record<string, string>>) => {
        if (!response.body) {
          return;
        }

        this.loadScriptAndBoot(member, response.body);
      });
  }

  showHide(): void {
    if (this.shouldHide()) {
      this.hide();
    } else {
      this.show();
    }
  }

  private loadScriptAndBoot(
    member: Member,
    payload: Record<string, string>,
  ): void {
    const url = environment.celloUrl;
    ScriptStore.push({
      name: url,
      src: url,
    });
    this.scriptService.setScripts();
    this.scriptService.loadScript(url, 'module', true).then(() => {
      let elapsedTime = 0;
      const interval = 100;
      const maxTime = 5000;

      const checkExist = setInterval(() => {
         
        // @ts-ignore
        if (globalThis.cello) {
          clearInterval(checkExist);
          this.boot(member, payload);
        } else {
          elapsedTime += interval;
          if (elapsedTime >= maxTime) {
            clearInterval(checkExist);
          }
        }
      }, interval);
    });
  }

  private boot(member: Member, payload: Record<string, string>): void {
     
    // @ts-ignore
    globalThis.cello.cmd.push(async (cello: any) => {
      try {
        await cello.boot({
          productId: payload.productId,
          token: payload.token,
          language: member.language,
          hideDefaultLauncher: this.shouldHide(),
          productUserDetails: {
            firstName: member.firstName,
            lastName: member.lastName,
            fullName: member.name,
            email: member.identifier,
          },
        });
      } catch (error) {
        console.error('Failed to boot cello:', error);
      }
    });
  }

  private shouldHide(): boolean {
    const url = this.router.url;
    return url.indexOf('/reporting') === 0 || url.indexOf('/content?') === 0;
  }

  private show(): void {
    let elapsedTime = 0;
    const interval = 100;
    const maxTime = 5000;

    const checkExist = setInterval(() => {
      // @ts-ignore
      if (globalThis.Cello) {
        clearInterval(checkExist);
         
        // @ts-ignore
        globalThis?.Cello('show');
      } else {
        elapsedTime += interval;
        if (elapsedTime >= maxTime) {
          clearInterval(checkExist);
        }
      }
    }, interval);
  }

  private hide(): void {
    let elapsedTime = 0;
    const interval = 100;
    const maxTime = 5000;

    const checkExist = setInterval(() => {
      // @ts-ignore
      if (globalThis.Cello) {
        clearInterval(checkExist);
         
        // @ts-ignore
        globalThis?.Cello('hide');
      } else {
        elapsedTime += interval;
        if (elapsedTime >= maxTime) {
          clearInterval(checkExist);
        }
      }
    }, interval);
  }
}
