import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['page404.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class Page404Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
