import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { take } from 'rxjs/operators';

import { InvitationService } from '@app/core/services/invitation.service';
import { CsvFileHeaderColumnNames } from './member-invite-csv-dialog.constants';

@Component({
  selector: 'app-member-invite-csv-dialog',
  templateUrl: './member-invite-csv-dialog.component.html',
  styleUrls: ['./member-invite-csv-dialog.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class MemberInviteCsvDialogComponent implements OnInit {
  invitationCsvFile: File | undefined;
  isValid = false;
  rowsNumber = 0;
  delimiter = ',';

  constructor(
    public dialogRef: MatDialogRef<MemberInviteCsvDialogComponent>,
    public invitationService: InvitationService,
  ) {}

  ngOnInit(): void {}

  onZipFileChange(event: NgxDropzoneChangeEvent): void {
    if (event.addedFiles[0]) {
      this.validateFile(event.addedFiles[0]);
    }
  }

  importCsv(): void {
    if (this.invitationCsvFile) {
      this.invitationService
        .getImportCsvUrl(this.invitationCsvFile.name)
        .pipe(take(1))
        .subscribe((url: string) => {
          if (this.invitationCsvFile) {
            this.invitationService.uploadFile(
              this.invitationCsvFile,
              url,
              this.delimiter,
            );
            this.dialogRef.close();
          }
        });
    }
  }

  private validateFile(file: File): void {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (!fileReader.result) {
        return;
      }

      const csvString = fileReader.result as string;
      // First header column name is row_id.
      // We need to find the delimiter by checking the next character
      this.delimiter = csvString.charAt(6);
      const rows = this.csvStringToArray(csvString).filter(
        r => r[0], // Do not count empty new lines
      );

      if (!rows.length) {
        return;
      }

      this.rowsNumber = rows.length - 1;
      const headerRow: string[] = rows[0].map(v => v.toLowerCase());
      this.isValid =
        !!headerRow &&
        CsvFileHeaderColumnNames.every(n => headerRow.includes(n));
      this.invitationCsvFile = file;
    };
    fileReader.readAsText(file);
  }

  private csvStringToArray(csvString: string): string[][] {
    const rows: string[][] = [[]];
    const regexPattern = new RegExp(
      // Delimiters.
      `(\\${this.delimiter}|\\r?\\n|\\r|^)` +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        `([^\"\\${this.delimiter}\\r\\n]*))`,
      'gi',
    );
    let match = regexPattern.exec(csvString);
    while (match) {
      if (match[1].length && match[1] !== this.delimiter) {
        rows.push([]);
      }
      rows[rows.length - 1].push(match[2] ? match[2] : match[3]);
      match = regexPattern.exec(csvString);
    }
    return rows;
  }
}
