 
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonApiQueryData } from '@elearnio/angular2-jsonapi';
import { throttleTime, take } from 'rxjs/operators';

// Models
import { Member } from '@models/member.model';
import { Course } from '@models/course.model';
import { CourseActivity } from '@models/course/course-activity.model';
import { CourseMemberAnswer } from '@models/course/member/course-member-answer.model';

// Services
import { MemberService } from '@services/member.service';
import { CourseService } from '@services/course.service';
import { Datastore } from '@services/datastore';
import { ActivityEventQueueService } from '@services/activity-event-queue.service';
import { CourseMemberAnswerService } from '@services/course-member-answer.service';
import {
  H5PEventService,
  IH5PEventMultiResult,
  IH5PEventSingleResult,
} from '@services/h5p-event.service';

// Shared
import { H5PInitialization } from '@shared/h5p/assets/h5p-initialization';
import { environment } from '@environment';

// Constants
import { H5PEvent } from '@core/constants/h5p-events.constant';
import { CourseMemberResult } from '@app/models/course/member/course-member-result.model';
import { ELEARNIO_ACTIVITIES } from '@app/core/constants/elearnio-activities.constant';
import { Subscription, fromEvent } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { H5PPlayerTimesUpDialogComponent } from '../h5p-player-timesup/h5p-player-timesup-dialog.component';
import { PermissionService } from '@app/core/services/permission.service';

export interface IH5PEventStatement {
  userId: string;
  courseId: string;
  activityId: string;
  action: string;
  result: IH5PEventMultiResult | IH5PEventSingleResult | null;
}

@Component({
  selector: 'app-h5p-player-standalone-component',
  templateUrl: './h5p-player-standalone.component.html',
  styleUrls: ['h5p-player-standalone.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class H5pPlayerStandaloneComponent implements OnInit, OnDestroy {
  @ViewChild('h5pContent') h5pContent!: ElementRef<HTMLDivElement>;
  public multiple: boolean | undefined;
  public displaySpinner = true;
  public displayTimer = false;
  public hasPrevious = false;
  public hasNext = false;
  public showCourseNavigation = false;
  public isLastActivity = false;
  public adminPreview = false;
  public isPreview = false;
  progress = 0;
  index = 0;
  durationInMinutes = 0;
  courseId = '';
  course: Course | undefined;
  learningPathId = '';
  showIcon = false;
  showFade = true;
  mobileQuery: MediaQueryList;
  activityElement: HTMLElement | undefined;
  private courseMemberResult!: CourseMemberResult;
  private subscribedToXAPI = false;
  private userId = '';
  private userName = '';
  private userLanguage = '';
  private currentActivityId = '';
  private currentActivityType = '';
  private courseActivities: CourseActivity[] = [];
  private courseActivitiesAnswers: CourseMemberAnswer[] = [];
  private isActivityCompleted = false;
  private hasCompletedCourse = false;
  private isDestroyedComponent = false;
  private currentH5PInstance: any = null;
  private currentH5PQuizInstance: any = null;
  private activityElementObservable: Subscription = new Subscription();
  private mobileQueryListener: () => void;
  private mutationObserver: MutationObserver = new MutationObserver(() => {});
  private currentActivityTypeStore = '';

  constructor(
    private datastore: Datastore,
    private route: ActivatedRoute,
    private router: Router,
    private h5pInitialization: H5PInitialization,
    private h5pEventService: H5PEventService,
    private courseService: CourseService,
    private activityEventQueue: ActivityEventQueueService,
    private courseMemberAnswerService: CourseMemberAnswerService,
    private memberService: MemberService,
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2,
    media: MediaMatcher,
    public dialog: MatDialog,
    private permissionService: PermissionService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 960px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit(): void {
    this.memberService
      .current()
      .pipe(take(1))
      .subscribe((member: Member | null) => {
        if (!member) {
          this.router.navigateByUrl('courses/my');
        }
        this.router.onSameUrlNavigation = 'reload';
        this.route.queryParams.pipe(take(1)).subscribe(data => {
          if (data.courseId) {
            this.courseId = data.courseId;
            this.userId = member?.id || '';
            this.userName = member?.name || '';
            this.userLanguage = member?.language || 'de';
            this.index = parseInt(data.index, 10);
            this.learningPathId = data.learningPathId || '';

            this.setPreviewAndRunContent(data);
          } else {
            this.router.navigateByUrl('courses/my');
          }
        });
      });
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  public setPreviewAndRunContent(data: any): void {
    if (data.adminPreview || data.isPreview) {
      this.permissionService
        .getPermission('Course', this.courseId)
        .pipe(take(1))
        .subscribe(permission => {
          if (permission?.update) {
            this.adminPreview = data.adminPreview;
            this.isPreview = data.adminPreview || data.isPreview;
          }
          this.runContent();
        });
    } else {
      this.runContent();
    }
  }

  public runContent(): void {
    this.startShowContent();
    if (!this.isPreview) {
      this.loadCourseMemberResult();
    }
  }

  public startShowContent(): void {
    this.displaySpinner = true;

    this.datastore
      .findRecord(Course, this.courseId, {
        include: [
          'course_activity_sections',
          'course_activity_sections.course_activities',
        ].join(','),
      })
      .pipe(take(1))
      .subscribe((course: Course) => {
        this.course = course;
        if (this.courseMemberResult) {
          this.course.courseResult = this.courseMemberResult;
        }
        this.handleCourse();
      });
  }

  public async initializeH5P(courseActivity: CourseActivity): Promise<void> {
    // @ts-ignore
    window.H5P = { ...window.H5P, preventInit: true };
    await this.h5pInitialization.initGlobalH5P('', this.course?.language || '');
    this.h5pInitialization.setupGlobalH5PEditor(
      '',
      this.course?.language || '',
    );

    this.subscribeToXAPI();
    this.loadLibrary(courseActivity);
  }

  public async loadLibrary(courseActivity: CourseActivity): Promise<void> {
    this.setShowCourseNavigation(courseActivity);
    this.isLastActivity = this.index === this.courseActivities.length - 1;
    this.displaySpinner = true;
    this.currentActivityId = courseActivity.id;
    this.currentActivityType = courseActivity.h5pLibrary;
    const usersAnswer =
      this.courseActivitiesAnswers[this.findCurrentActivityAnswerIdx()];

    const contentUserDataObject =
      (usersAnswer && usersAnswer.content && JSON.parse(usersAnswer.content)) ||
      {};
    this.isActivityCompleted = contentUserDataObject.result?.completion;
    let contentUserDataState = contentUserDataObject.result?.state;
    if (contentUserDataState) {
      try {
        contentUserDataState = JSON.stringify(
          Object.assign(JSON.parse(contentUserDataState), {
            completion: contentUserDataObject.result?.completion,
            success: contentUserDataObject.result?.success,
          }),
        );
      } catch {}
    }
    const contentUserData = { 0: { state: contentUserDataState } };

    this.setNavigationArrows(courseActivity.h5pLibrary, null);

     
    // @ts-ignore
    await globalThis.H5PEditor.loadLibrary(courseActivity.h5pLibrary, () => {
      setTimeout(() => {
        const contentUrlPath =
          courseActivity?.h5pLibrary === H5PEvent.VERSION.SCORM
            ? '/content-scorm/'
            : '/content/';
        this.displaySpinner = false;
        // @ts-ignore
        globalThis.H5PIntegration.contents[`cid-${courseActivity.contentId}`] =
          {
            displayOptions: {
              copy: false,
              copyright: false,
              embed: false,
              export: false,
              frame: false,
              icon: false,
            },
            fullScreen: false,
            jsonContent: courseActivity.contentJSON,
            library: courseActivity.h5pLibrary,
            contentUrl: `${environment.h5pBaseUrl}${contentUrlPath}${courseActivity?.contentId}`,
            contentUserData,
            previousChildrenOrder:
              contentUserDataObject?.result?.children?.map(
                (c: any) => c.subContentId,
              ) || [],
            assignmentDate: this.course?.courseMemberAssignmentDate,
            activityId: courseActivity.id,
            userId: this.userId,
            userName: this.userName,
            userLanguage: this.userLanguage,
            adminPreview: this.adminPreview,
          };
        this.h5pContent?.nativeElement?.classList?.remove('h5p-initialized');
        this.h5pContent.nativeElement.innerHTML = '';

        // @ts-ignore
        globalThis.H5P.jQuery('.h5p-content:not(.h5p-initialized)').data(
          'content-id',
          courseActivity.contentId,
        );

        // @ts-ignore
        globalThis.H5P.init(globalThis.H5P.jQuery('.h5p-player-container'));

        // Create an observer instance linked to the callback function
        const mutationCb = (mutationsList: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const mutation of mutationsList) {
            if (this.currentActivityType !== this.currentActivityTypeStore) {
              this.showScrollIcon();
              this.currentActivityTypeStore = this.currentActivityType;
            }
          }
        };
        this.mutationObserver = new MutationObserver(mutationCb);
        // Start observing the target node for configured mutations
        this.mutationObserver.observe(this.h5pContent.nativeElement, {
          attributes: true,
          childList: true,
          subtree: true,
          characterData: false,
        });
      }, 0);
    });
  }

  previous(): void {
    this.mutationObserver.disconnect();
    this.currentH5PInstance = null;
    this.loadLibrary(this.courseActivities[--this.index]);
    this.setNavigationArrows(
      this.courseActivities[this.index].h5pLibrary,
      null,
    );
  }

  next(): void {
    this.mutationObserver.disconnect();
    if (
      this.currentH5PInstance?.libraryInfo?.versionedName ===
        H5PEvent.VERSION.TASK &&
      (!this.currentH5PInstance.feedback ||
        !!this.currentH5PInstance.changedTask)
    ) {
      this.currentH5PInstance.completeTask('next');
      return;
    }
    this.currentH5PInstance = null;
    this.index++;
    if (this.index === this.courseActivities.length) {
      this.back2Course();
    } else {
      this.loadLibrary(this.courseActivities[this.index]);
      this.setNavigationArrows(
        this.courseActivities[this.index].h5pLibrary,
        null,
      );
    }
  }

  back2Course(): void {
    const queryParams = {
      queryParams: {},
    };
    if (this.adminPreview) {
      queryParams.queryParams = {
        adminPreview: true,
      };
    } else if (this.isPreview) {
      queryParams.queryParams = {
        isPreview: true,
      };
    }

    if (this.learningPathId) {
      Object.assign(queryParams.queryParams, {
        learningPathId: this.learningPathId,
      });
    }

    this.router.navigate([`/courses/${this.courseId}/overview`], queryParams);
  }

  async ngOnDestroy(): Promise<void> {
    this.isDestroyedComponent = true;
    this.activityElementObservable.unsubscribe();
    this.mutationObserver.disconnect();
    // @ts-ignore
    if (window.H5P && window.H5P.externalDispatcher) {      // @ts-ignore
      window.H5P.externalDispatcher.off('xAPI');
    }

    await this.saveInteractiveVideoProgress();
  }

  scrollToBottom(): void {
    if (this.activityElement) {
      this.activityElement.scroll({
        top: this.activityElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  }

  timerZero(): void {
    this.dialog
      .open(H5PPlayerTimesUpDialogComponent, {
        disableClose: true,
      })
      .afterClosed()
      .subscribe(() => {
        this.currentH5PQuizInstance.goToResults();
      });
    this.displayTimer = false;
    this.durationInMinutes = 0;
  }

  private async saveInteractiveVideoProgress(): Promise<void> {
    // @ts-ignore
    const h5pInstances = window.H5P?.instances;
    if (
      this.isActivityCompleted ||
      !h5pInstances ||
      !h5pInstances.length ||      // @ts-ignore
      h5pInstances[h5pInstances.length - 1].libraryInfo.versionedName !==
        H5PEvent.VERSION.VIDEO
    ) {
      return;
    }
    const currentActivity = this.courseActivities.find(
      a => a.id === this.currentActivityId,
    );

    if (currentActivity?.h5pLibrary !== H5PEvent.VERSION.VIDEO) {
      return;
    }

    const videoInstance = h5pInstances[h5pInstances.length - 1];
    await this.parseEvent(
      videoInstance.getXAPIEventData('answered').event,
      videoInstance,
    );
    this.changeNavigationArrows(
      videoInstance.getXAPIEventData('answered').event,
      videoInstance,
    );
  }

  private loadCourseMemberResult(): void {
    this.datastore
      .findAll(CourseMemberResult, {
        filter: {
           
          latest_result: `${this.courseId},${this.userId}`,
        },
      })
      .pipe(take(1))
      .subscribe(courseMemberResult => {
        const latestResult = courseMemberResult.getModels()[0];

        if (
          !latestResult ||
          (!latestResult.courseMemberId && latestResult.progress < 100)
        ) {
          this.router.navigateByUrl('courses/my');
          return;
        }

        this.courseMemberResult = latestResult;
        if (this.course) {
          this.course.courseResult = this.courseMemberResult;
        }
      });
  }

  private handleCourse(): void {
    if (this.course) {
      this.courseActivities = this.getCourseActivities(this.course);
    }

    if (this.courseActivities.length === 0) {
      this.back2Course();
      return;
    }

    const courseActivityIds = this.courseActivities.map(cA => cA.id);
    this.multiple = this.courseActivities.length > 1;

    if (!this.isPreview) {
      this.courseMemberAnswerService
        .select()
        .filterByActivities(courseActivityIds)
        .filterByCourseAndMember(this.courseId, this.userId)
        .request()
        .pipe(take(1))
        .subscribe(
          (courseMemberAnswer: JsonApiQueryData<CourseMemberAnswer>) => {
            this.courseActivitiesAnswers = courseMemberAnswer.getModels();
            this.setActivitiesOrder();
            this.setProgress();
            if (this.progress === 100) {
              this.hasCompletedCourse = true;
            }
            this.initializeH5P(this.courseActivities[this.index]);
          },
        );
    } else {
      this.setActivitiesOrder();
      this.setProgress();
      this.initializeH5P(this.courseActivities[0]);
    }
  }

  private setNavigationArrows(
    versionedLibraryName: string,
    instance: any,
  ): void {
    this.hasNext =
      (!!this.isActivityCompleted ||
        this.h5pEventService.allowNavigation(versionedLibraryName) ||
        this.h5pEventService.isAnsweredTaskActivity(
          versionedLibraryName,
          instance,
        )) &&
      this.courseActivities.length >= this.index + 1;
    this.hasPrevious = this.index > 0;
  }

  private getCourseActivities(course: Course): CourseActivity[] {
    let courseActivities: CourseActivity[] = [];
    for (const section of this.courseService.sortByOrder(
      course.courseActivitiesSections,
    ) || []) {
      courseActivities = courseActivities.concat(
        this.courseService.sortByOrder(section.courseActivities),
      );
    }
    return courseActivities.filter(
      a => a.h5pLibrary !== ELEARNIO_ACTIVITIES.LABEL,
    );
  }

  private subscribeToXAPI(): void {
    if (!this.subscribedToXAPI && !this.isDestroyedComponent) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;      // @ts-ignore
      window.H5P.externalDispatcher.on('xAPI', function (event) {
        if (!event) {
          return;
        }

        // @ts-ignore
        self.currentH5PInstance = this;
        if (self.h5pEventService.showTimerEvent(event)) {
          // @ts-ignore
          self.currentH5PQuizInstance = this;
        }
        // @ts-ignore
        self.handleXAPI(event, this);
      });
      this.subscribedToXAPI = true;
    }
  }

  private async handleXAPI(event: any, instance: any): Promise<void> {
    if (this.h5pEventService.shouldParseEvent(event, instance)) {
      await this.parseEvent(event, instance);
      if (instance.libraryInfo.versionedName !== H5PEvent.VERSION.TASK) {
        // Task activity is handled from the activity itself because
        // it does not have submit buttons
        this.changeNavigationArrows(event, instance);
      }
    } else if (this.h5pEventService.isShowCustomNavigationEvent(event)) {
      const currentActivity: CourseActivity | null = this.datastore.peekRecord(
        CourseActivity,
        this.currentActivityId,
      );
      if (currentActivity) {
        this.setShowCourseNavigation(currentActivity);
      }
    } else if (this.h5pEventService.isShowBaseNavigationEvent(event)) {
      this.showCourseNavigation = true;
    } else if (this.h5pEventService.isResetEvent(event)) {
      this.resetAnswer();
      this.isActivityCompleted = false;
      this.setNavigationArrows(instance.libraryInfo.versionedName, instance);
    } else if (this.h5pEventService.isSkipEvent(event)) {
      this.next();
    } else if (this.h5pEventService.isExitEvent(event)) {
      if (instance.activityId === this.currentActivityId) {
        this.back2Course();
      }
    } else if (this.h5pEventService.isEnableNavigationEvent(event)) {
      this.hasNext = true;
      this.hasPrevious = this.index > 0;
    } else if (this.h5pEventService.isDisableNavigationEvent(event)) {
      this.hasNext = false;
      this.hasPrevious = false;
    } else if (this.h5pEventService.isNextEvent(event)) {
      this.currentH5PInstance = null;
      if (
        !this.course?.freeActivitiesOrder &&
        instance.libraryInfo?.versionedName === H5PEvent.VERSION.TASK
      ) {
        this.back2Course();
        return;
      }
      this.next();
    } else if (this.h5pEventService.isPrevEvent(event)) {
      this.previous();
    } else if (this.h5pEventService.showScrollIconEvent(event)) {
      this.showScrollIcon(event.data.statement.elementClass);
      if (
        this.currentActivityType === H5PEvent.VERSION.VIDEO ||
        (this.currentActivityType === H5PEvent.VERSION.IMAGE_HOTSPOTS &&
          event.data.statement.scrollForHotspot)
      ) {
        const container = this.el.nativeElement.querySelector('.h5p-container');
        const scrollFade = this.el.nativeElement.querySelector(
          '#h5p-scroll-fade-footer',
        );
        if (container && scrollFade) {
          this.renderer.appendChild(container, scrollFade);
        }
      }
    } else if (this.h5pEventService.hideScrollIconEvent(event)) {
      this.hideScrollIcon();
    } else if (this.h5pEventService.showTimerEvent(event)) {
      this.displayTimer = true;
      this.durationInMinutes = event.data.statement.durationInMinutes;
      const eventStatement: IH5PEventStatement = await this.parseEvent(
        event,
        instance,
      );
      this.checkTimeLeft(eventStatement);
    } else if (this.h5pEventService.hideTimerEvent(event)) {
      this.displayTimer = false;
      this.durationInMinutes = 0;
    }
  }

  private async parseEvent(
    event: any,
    instance: any,
  ): Promise<IH5PEventStatement> {
    const eventStatement: IH5PEventStatement = {
      userId: this.userId,
      courseId: this.courseId,
      activityId: this.currentActivityId,
      action: event.getVerb(),      // @ts-ignore
      result: this.h5pEventService.parseEvent(event, instance),
    };
    if (!this.isActivityCompleted) {
      this.isActivityCompleted = eventStatement.result?.completion || false;
    }
    const usersAnswerIndex = this.findCurrentActivityAnswerIdx();

    if (usersAnswerIndex > -1) {
      this.courseActivitiesAnswers[usersAnswerIndex].content =
        JSON.stringify(eventStatement);
      if (instance.libraryInfo?.versionedName !== H5PEvent.VERSION.TASK) {
        this.setProgress();
      }
    } else {
      this.courseActivitiesAnswers.push(
        this.datastore.createRecord(CourseMemberAnswer, {
          content: JSON.stringify(eventStatement),
        }),
      );
      if (eventStatement.result?.completion) {
        this.progress = Number(
          Math.round(
            (this.getCompletedAnswersNumber() * 100) /
              this.courseActivities.length,
          ),
        );
        this.courseService.setCourseProgress(this.courseId, this.progress);
      }
    }

    if (
      !this.course?.noCompletion &&
      !this.isPreview &&
      (!this.course?.courseResult || this.course.courseResult.progress < 100) &&
      !this.hasCompletedCourse
    ) {
      if (this.progress === 100) {
        this.hasCompletedCourse = true;
      }
      this.setCourseRating(event, instance);
      await this.activityEventQueue.pushEvent(eventStatement).toPromise();
    }

    if (event?.data?.options?.goNext) {
      this.next();
    }

    return eventStatement;
  }

  private changeNavigationArrows(event: any, instance: any): void {
    this.setNavigationArrows(instance.libraryInfo.versionedName, instance);
    const currentActivity = this.courseActivities.find(
      a => a.id === this.currentActivityId,
    );
    if (
      this.h5pEventService.isCompletedCustomNavigationActivity(
        instance.libraryInfo.versionedName,
        event.getVerb(),
      ) &&
      (!currentActivity ||
        !this.h5pEventService.isCustomNavigationActivity(
          currentActivity.h5pLibrary,
          this.mobileQuery.matches,
        ) ||
        currentActivity.id === instance.activityId)
    ) {
      this.showCourseNavigation = true;
    }
    this.changeDetectorRef.detectChanges();
  }

  private setCourseRating(event: any, instance: any): void {
    if (
      this.courseMemberResult &&
      this.h5pEventService.isCompletedFeedbackActivity(
        instance.libraryInfo.versionedName,
        event.getVerb(),
      )
    ) {
      const courseRating = this.h5pEventService.getRatingStarFeedback(instance);
      if (courseRating) {
        this.courseMemberResult.courseRating = courseRating;
        this.courseMemberResult.save().pipe(take(1)).subscribe();
      }
    }
  }

  private resetAnswer(): void {
    const usersAnswerIndex = this.findCurrentActivityAnswerIdx();

    if (usersAnswerIndex > -1) {
      this.courseActivitiesAnswers.splice(usersAnswerIndex, 1);
      this.setProgress();
    }
  }

  private findCurrentActivityAnswerIdx(): number {
    return this.courseActivitiesAnswers
      .map(a => JSON.parse(a.content))
      .findIndex(a => a.activityId === this.currentActivityId);
  }

  private setProgress(): void {
    const currentProgress =
      this.getCompletedAnswersNumber() / this.courseActivities.length;
    this.progress = Number(Math.round(currentProgress * 100));
  }

  private setShowCourseNavigation(courseActivity: CourseActivity): void {
    this.showCourseNavigation =
      !this.h5pEventService.isCustomNavigationActivity(
        courseActivity.h5pLibrary,
        this.mobileQuery.matches,
      );
  }

  private getCompletedAnswersNumber(): number {
    return this.courseActivitiesAnswers.reduce((acc, val) => {
      try {
        const answer = JSON.parse(val.content);
        if (answer && answer.result && answer.result.completion) {
          acc += 1;
        }
      } catch {}

      return acc;
    }, 0);
  }

  private setActivitiesOrder(): void {
    if (this.course?.freeActivitiesOrder || this.adminPreview) {
      this.setFreeActivitiesOrder();
    } else {
      this.setSequenceActivitiesOrder();
    }
  }

  private setFreeActivitiesOrder(): void {
    const currentIndex = this.index;
    const nextActivities = this.courseActivities.splice(
      currentIndex,
      this.courseActivities.length - currentIndex,
    );
    this.courseActivities = nextActivities.concat(this.courseActivities);
    this.index = 0;
  }

  private setSequenceActivitiesOrder(): void {
    const answersContent = this.courseActivitiesAnswers.map(a =>
      JSON.parse(a.content),
    );

    let firstAnsweredActivity = this.courseActivities.findIndex(cA => {
      const activityAnswer = answersContent.find(a => cA.id === a.activityId);
      return !activityAnswer || !activityAnswer.result.completion;
    });

    if (firstAnsweredActivity === -1) {
      firstAnsweredActivity = isNaN(this.index) ? 0 : this.index;
    }

    if (isNaN(this.index) || firstAnsweredActivity < this.index) {
      this.index = firstAnsweredActivity;
    }
  }

  private showScrollIcon(innerElementClass = ''): void {
    if (!this.mobileQuery.matches) {
      return;
    }
    this.activityElementObservable.unsubscribe();
    this.activityElementObservable = new Subscription();
    this.activityElement = this.getCurrentActivityElement(innerElementClass);
    if (!this.activityElement) {
      return;
    }
    if (innerElementClass === 'h5p-drag-draggables-container') {
      this.showFade = false;
    }
    if (this.activityElement.scrollTop > 0) {
      this.showIcon =
        Math.ceil(this.activityElement.scrollTop) <
        this.activityElement.scrollHeight - this.activityElement.offsetHeight;
    } else {
      this.showIcon =
        this.activityElement.scrollHeight > this.activityElement.clientHeight;
    }
    this.addActivityElementObservable();
  }

  private addActivityElementObservable(): void {
    this.activityElementObservable.add(
       
      fromEvent(this.activityElement!, 'scroll')
        .pipe(throttleTime(200, undefined, { leading: true, trailing: true }))
        .subscribe(event => {
          if (event.target) {
            const target = event.target as HTMLElement;
            this.showIcon =
              Math.ceil(target.scrollTop) <
              target.scrollHeight - target.offsetHeight;
          }
        }),
    );
  }

  private hideScrollIcon(): void {
    this.activityElementObservable.unsubscribe();
    this.activityElement = undefined;
    this.showIcon = false;
    this.showFade = true;
  }

  private getCurrentActivityElement(
    innerElementClass: string,
  ): HTMLElement | undefined {
    if (innerElementClass) {
      const interactionOuter =
        this.el.nativeElement.getElementsByClassName(innerElementClass);
      for (const element of interactionOuter) {
        if (
          element.scrollHeight > element.clientHeight &&
          element.checkVisibility()
        ) {
          return element;
        }
      }
    } else {
      return this.el.nativeElement.getElementsByClassName('h5p-container')[0];
    }
  }

  private checkTimeLeft(eventStatement: IH5PEventStatement): void {
    if (eventStatement && eventStatement.result?.state) {
      const currentState = JSON.parse(eventStatement.result?.state);
      if (currentState && currentState.startTime) {
        const currentTime = Date.now();
        const currentDateTime = new Date(currentTime);
        const startTime = new Date(currentState.startTime);
        const diffTime = new Date(currentTime - currentState.startTime);

        if (
          currentDateTime.getFullYear() === startTime.getFullYear() &&
          currentDateTime.getMonth() === startTime.getMonth() &&
          currentDateTime.getDate() === startTime.getDate()
        ) {
          if (
            diffTime.getMinutes() * 60 + diffTime.getSeconds() >
            this.durationInMinutes * 60
          ) {
            this.durationInMinutes = 0;
          } else {
            this.durationInMinutes =
              this.durationInMinutes -
              (diffTime.getMinutes() + diffTime.getSeconds() / 60);
          }
        }
      }
    }
  }
}
