/* tslint:disable:triple-equals */
 
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Datastore } from '@services/datastore';
import { FeatureFlag } from '@models/feature-flag/feature-flag.model';
import { JsonApiQueryData } from '@elearnio/angular2-jsonapi';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureFlags: FeatureFlag[] = [];
  private isLoading = false;
  private concurrentRequestSubject = new Subject<FeatureFlag[]>();

  constructor(private datastore: Datastore) {}

  isFeatureFlagEnabled(key: string): Observable<boolean> {
    const subject = new ReplaySubject<boolean>(1);

    this.getFeatureFlags()
      .pipe(take(1))
      .subscribe(featureFlags => {
        subject.next(!!featureFlags.find(flag => flag.key === key)?.enabled);
      });

    return subject.asObservable();
  }

  private getFeatureFlags(): Observable<FeatureFlag[]> {
    const subject = new ReplaySubject<FeatureFlag[]>(1);

    if (this.isLoading) {
      return this.concurrentRequestSubject.asObservable();
    }

    if (!this.featureFlags.length) {
      this.isLoading = true;

      this.datastore
        .findAll(FeatureFlag)
        .pipe(take(1))
        .subscribe((featureFlags: JsonApiQueryData<FeatureFlag>) => {
          this.featureFlags = featureFlags.getModels();
          this.concurrentRequestSubject.next(this.featureFlags);
          this.isLoading = false;
          subject.next(this.featureFlags);
        });
    } else {
      subject.next(this.featureFlags);
    }

    return subject;
  }
}
