/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { CourseActivitySection } from './course-activity-section.model';
import { type2img } from '../../core/helper/h5p-icons-helper';

@JsonApiModelConfig({
  type: 'course_activities',
})
export class CourseActivity extends JsonApiModel {
  @Attribute({ serializedName: 'course_activity_section_id' })
  courseActivitySectionId!: number;

  @Attribute({ serializedName: 'activity_config' })
  activityConfig!: any;

  @Attribute({ serializedName: 'content_json' })
  contentJSON!: string;

  @Attribute({ serializedName: 'h5p_library' })
  h5pLibrary!: string;

  @Attribute({ serializedName: 'content_id' })
  contentId!: string;

  @Attribute({ serializedName: 'disabled' })
  disabled!: boolean;

  @Attribute()
  order!: number;

  @Attribute({ serializedName: 'activity_done' })
  activityDone: boolean | undefined;

  @BelongsTo()
  courseActivitySection!: CourseActivitySection;

  private activityIcon = '';

  private titlesInLibraries: { [key: string]: string } = {
    'H5P.InteractiveVideo 1.21': 'interactiveVideo/video',
    'H5P.Audio 1.4': 'wizard/audioWrapper',
    'H5P.ContentPage 1.0': 'wizard/introPage',
    'H5P.Checklist 1.0': 'wizard/introPage',
    'H5P.Slides 1.0': 'presentation/introPage',
    'H5P.ImageHotspots 1.8': 'imageHotspots/hotspotsInfo',
    'H5P.File 1.0': 'wizard/fileWrapper',
    'H5P.Feedback 1.0': 'wizard/introPage',
    'H5P.Flashcards 1.5': 'wizard/basics',
    'H5P.Link 1.3': 'wizard/linkWrapper',
    'H5P.QuestionSet 1.17': 'wizard/introPage',
    'H5P.MemoryGame 1.3': 'wizard/basics',
    'H5P.Blanks 1.12': 'blanks/basics',
    'H5P.DragText 1.8': 'dragWords/basics',
    'H5P.Essay 1.2': 'wizard/introPageTab',
    'H5P.DragQuestion 1.14': 'question/basics',
    'H5P.ImageHotspotQuestion 1.8':
      'imageHotspotQuestion/backgroundImageSettings',
    'H5P.Dialogcards 1.8': 'wizard/basics',
    'H5P.Timeline 1.1': 'timeline/introPage',
    'H5P.MediaTask 1.0': 'wizard/basics',
    'H5P.Scorm 1.0': 'wizard/introPage',
    'H5P.ImageSlider 1.0': 'wizard/basics',
    'H5P.Task 1.0': 'wizard/introPage',
  };

  public get description(): string {
    if (!this.titlesInLibraries[this.h5pLibrary]) {
      return '';
    }
    const [wizard, tab1] = this.titlesInLibraries[this.h5pLibrary].split('/');
    if (this.content[wizard] && this.content[wizard][tab1]) {
      if (this.content[wizard][tab1]['taskDescription']) {
        return this.content[wizard][tab1]['taskDescription'];
      }

      return this.content[wizard][tab1]['description'] || '';
    }

    return '';
  }

  public get icon(): string {
    this.activityIcon = type2img(this.h5pLibrary.split(' ')[0]);
    return this.activityIcon;
  }

  public get name(): string {
    return this.title;
  }

  public get title(): string {
    if (!this.titlesInLibraries[this.h5pLibrary]) {
      return '';
    }
    const [wizard, tab1] = this.titlesInLibraries[this.h5pLibrary].split('/');
    return this.content[wizard][tab1]['title'];
  }

  public set title(newTitle: string) {
    if (!this.titlesInLibraries[this.h5pLibrary]) {
      return;
    }
    const [wizard, tab1] = this.titlesInLibraries[this.h5pLibrary].split('/');
    const parsedContent = this.content;
    parsedContent[wizard][tab1]['title'] = newTitle;
    this.contentJSON = JSON.stringify(parsedContent);
  }

   
  public get content(): any {
    try {
      return JSON.parse(this.contentJSON);
    } catch {
      return {};
    }
  }
}
