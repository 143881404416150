import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatSelectChange } from '@angular/material/select';

import { CourseCategoryHelper } from '@app/core/helper/course-category.helper';
import { CourseCategory } from '@models/course-category.model';
import { Resource } from './interfaces';
import { ILTCourse } from '@app/models/ilt-course.model';
import { LearningPath } from '@app/models/learning-path.model';
import { Course } from '@app/models/course.model';
import { ILTCourseEvent } from '@app/models/ilt-course/ilt-course-event.model';
import { DatePipe } from '@angular/common';
import { Member } from '@app/models/member.model';
import { multiSelectCenterBottomTopPosition } from '@core/constants/multi-select-position.constant';

@Component({
  selector: 'app-multi-select-local-search',
  templateUrl: './multi-select-local-search.component.html',
  styleUrls: ['./multi-select-local-search.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class MultiSelectLocalSearchComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('multiSelect', { static: true }) multiSelect!: MatSelect;
  @Input() initialIds: string[] = [];
  @Input() className!: any;
  @Input() placeholder = '';
  @Input() placeholderLabel = '';
  @Input() noEntriesFoundLabel = '';
  @Input() multiple = false;
  @Input() resources: Resource[] = [];
  @Input() selectAllDefault = false;
  @Input() selectIncludeChildren = true;
  @Input() multiSelectPosition = multiSelectCenterBottomTopPosition;
  @Output() selectResources = new EventEmitter<string[]>();
  @Output() selectEvent = new EventEmitter<Resource[]>();
  sortedResources: Resource[] = [];
  selectNodeDepthClasses = {
    ROOT: 'root',
    CHILD: 'child',
    GRANDCHILD: 'grandchild',
  };
  public multiLocalCtrl: UntypedFormControl = new UntypedFormControl();
  public multiLocalFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredResourcesMulti: ReplaySubject<Resource[]> = new ReplaySubject<
    Resource[]
  >(1);
  private onDestroy = new Subject<void>();

  constructor(private datePipe: DatePipe, public elementRef: ElementRef) {}

  ngOnInit(): void {
    this.multiLocalFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterResourcesMulti();
      });
  }

  ngAfterViewInit(): void {
    this.setInitialValue();

    if (this.multiSelectPosition) {
       
      this.multiSelect._positions = [
        {
          originX: this.multiSelectPosition.originX,
          originY: this.multiSelectPosition.originY,
          overlayX: this.multiSelectPosition.overlayX,
          overlayY: this.multiSelectPosition.overlayY,
        },
      ];
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectAllDefault && changes['resources']) {
      this.multiLocalCtrl.setValue(this.resources);
    }

    if (this.className === CourseCategory) {
      this.sortedResources = CourseCategoryHelper.sortCourseCategories(
        this.resources as CourseCategory[],
      );

      if (
        this.initialIds &&
        this.initialIds.length &&
        this.sortedResources.length
      ) {
        this.multiLocalCtrl.setValue(
          this.sortedResources.filter(
            resource => this.initialIds.indexOf(resource.id) > -1,
          ),
        );
      }
    } else if (
      this.className === ILTCourse ||
      this.className === LearningPath ||
      this.className === Course ||
      this.className === ILTCourseEvent ||
      this.className === Member
    ) {
      this.sortedResources = this.resources;
      if (
        this.initialIds &&
        this.initialIds.length &&
        this.sortedResources.length
      ) {
        this.multiLocalCtrl.setValue(
          this.sortedResources.filter(
            resource => this.initialIds.indexOf(resource.id) > -1,
          ),
        );
      }
    } else {
      this.sortedResources = this.resources;
    }

    this.filterResourcesMulti();
  }

  selectionChanged(selectedResources: MatSelectChange): void {
    const selectedIds = selectedResources.value.map((r: Resource) => r.id);
    if (this.className === CourseCategory && this.selectIncludeChildren) {
      const selectedCourseCategoryIds = [
        ...selectedIds,
        ...this.getSelectedChildren(selectedIds),
      ];
      this.selectResources.emit(selectedCourseCategoryIds);
    } else {
      this.selectResources.emit(selectedIds);
    }
    this.selectEvent.emit(selectedResources.value);
  }

  getNodeDepthClass(resource: Resource): string {
    if (this.className !== CourseCategory) {
      return this.selectNodeDepthClasses.ROOT;
    }

    const courseCategory = resource as CourseCategory;

    if (
      !courseCategory.parentId ||
      CourseCategoryHelper.hasNoAvailableAncestry(
        this.resources as CourseCategory[],
        courseCategory,
      )
    ) {
      return this.selectNodeDepthClasses.ROOT;
    }

    if (
      courseCategory.ancestry?.split('/').length === 1 ||
      !this.resources.find(cC => cC.id === courseCategory.parentId?.toString())
    ) {
      return this.selectNodeDepthClasses.CHILD;
    }

    return this.selectNodeDepthClasses.GRANDCHILD;
  }

  getName(resource: Resource): string {
    if (this.className === ILTCourseEvent) {
      return resource.eventName
        ? resource.eventName
        : this.datePipe.transform(resource.startDate, 'dd MMM') ?? '';
    }
    if (this.className === Member) {
      return resource.name + ' (' + resource.identifier + ')';
    }
    return resource.name;
  }

  private getSelectedChildren(ancestorCategoryIds: string[]): string[] {
    return this.resources
      .filter(cC => {
        const ancestors = cC.ancestry?.split('/') || [];
        return !!ancestors.find(
          (a: CourseCategory) => ancestorCategoryIds.indexOf(a.toString()) > -1,
        );
      })
      .map(cC => cC.id);
  }

  private setInitialValue(): void {
    this.filteredResourcesMulti
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        //  the option value is the id, hence the string comparison
        this.multiSelect.compareWith = (a: string, b: string) =>
          !!a && !!b && a === b;
      });
  }

  private filterResourcesMulti(): void {
    if (!this.sortedResources) {
      return;
    }
    let search = this.multiLocalFilterCtrl.value;
    if (!search) {
      this.filteredResourcesMulti.next(this.sortedResources.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredResourcesMulti.next(
      this.sortedResources.filter(resource => {
        if (this.className === ILTCourseEvent) {
          const name = resource.eventName
            ? resource.eventName
            : this.datePipe.transform(resource.startDate, 'dd MMM');
          return name.toLowerCase().indexOf(search) > -1;
        } else {
          return resource.name.toLowerCase().indexOf(search) > -1;
        }
      }),
    );
  }
}
