import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-elearnio-language-span',
  templateUrl: './elearnio-language-span.component.html',
  styleUrls: ['./elearnio-language-span.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class ElearnioLanguageSpanComponent {
  @Input() countryCode?: string = '';
  @Input() showCountryCode = true;

  constructor() {}
}
