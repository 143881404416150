import {
  Attribute,
  JsonApiModel,
  JsonApiModelConfig,
  JsonApiNestedModel,
  JsonModelConverter,
} from '@elearnio/angular2-jsonapi';

export class DashboardCourse extends JsonApiNestedModel {
  @Attribute({ serializedName: 'course_id' })
  id!: string;

  @Attribute({ serializedName: 'course_name' })
  courseName!: string;

  @Attribute({ serializedName: 'course_picture' })
  coursePicture!: string;

  @Attribute()
  progress!: number;

  isModelInitialization = (): boolean => true;

   
  fill(attributes: any): void {
     
    Object.assign(this, {
      id: attributes.course_id,
      courseName: attributes.course_name,
      coursePicture: attributes.course_picture,
      progress: attributes.progress,
    });
     
  }
}

@JsonApiModelConfig({
  type: 'dashboard/topcourses',
})
export class DashboardTopCourse extends JsonApiModel {
  @Attribute({
    serializedName: 'new_courses',
    converter: new JsonModelConverter(DashboardCourse, { hasMany: true }),
  })
  newCourses!: DashboardCourse[];

  @Attribute({
    serializedName: 'running_courses',
    converter: new JsonModelConverter(DashboardCourse, { hasMany: true }),
  })
  runningCourses!: DashboardCourse[];
}
