<div>
  <mat-form-field
    appearance="outline"
    class="full-width"
    [class.disable]="disabled"
    [class.disable-background]="disableBackground"
    [ngClass]="{ 'mat-form-field-warning mat-form-field-outline-warning': showWarning, 'small': sizeSmall, 'regular': sizeRegular }"
    [floatLabel]="extraOption ? 'always' : 'auto'"
  >
    <mat-label>
      {{ placeholder }}
    </mat-label>
    <mat-select  
      [required]="required"
      [placeholder]="extraOption || ''"
      disableOptionCentering
      panelClass="multi-select-server-side-select-panel"
      [formControl]="multiServerSideCtrl"
      [multiple]="multiple"
      (openedChange)="panelChange($event)"
      #multiSelect
    >
      <mat-select-trigger>
        {{ multiple ? (multiServerSideCtrl.value && multiServerSideCtrl.value[0]
            ? (className === MemberIdentifier ? multiServerSideCtrl.value[0].identifier : multiServerSideCtrl.value[0].name) : placeholder)
            : ((className === MemberIdentifier ? multiServerSideCtrl.value?.identifier : multiServerSideCtrl.value?.name) || placeholder)
        }}
        <span *ngIf="multiServerSideCtrl.value?.length > 1" class="additional-selection">
          (+{{multiServerSideCtrl.value.length - 1}} {{multiServerSideCtrl.value?.length === 2
            ? ('common.other' | translate)
            : ('common.others' | translate)
          }})
        </span>
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          [disableScrollToActiveOnOptionsChanged]="true"
          [noEntriesFoundLabel]="noEntriesFoundLabel"
          [placeholderLabel]="placeholderLabel"
          [formControl]="multiServerSideFilteringCtrl"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngIf="!multiple" [value]="undefined">-</mat-option>
      <mat-option *ngFor="let resource of filteredServerSideResources | async" [value]="resource">
        <app-training-name-with-state
          [text]="getName(resource)"
          [state]="resource.state"
        ></app-training-name-with-state>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
