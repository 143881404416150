import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MemberInviteEmailDialogComponent } from './member-invite-email-dialog.component';
import { MultiSelectServerSideSearchModule } from '../multi-select-server-side-search/multi-select-server-side-search.module';
import { DirectivesModule } from '@core/directives/directives.module';
import { MatInputModule } from '@angular/material/input';

export const imports = [
  CommonModule,
  TranslateModule,
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  FlexLayoutModule,
  MatButtonModule,
  MatSelectModule,
  MatToolbarModule,
  MultiSelectServerSideSearchModule,
  MatDialogModule,
  DirectivesModule,
  MatInputModule,
];

@NgModule({
  declarations: [MemberInviteEmailDialogComponent],
  imports: [...imports],
  exports: [MemberInviteEmailDialogComponent],
})
export class MemberInviteEmailDialogModule {}
