import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { CourseCategory } from '@app/models/course-category.model';
import { Course } from '@app/models/course.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Resource } from '../multi-select-server-side-search/interfaces';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MultiSelectServerSideSearchComponent } from '../multi-select-server-side-search/multi-select-server-side-search.component';
import { LocalStorageService } from '@app/core/services/operations.service';
import { TrainingsFilters } from '@core/constants/training-filters.constants';
import { ToolbarFiltersTypes } from '@core/constants/toolbar-filters.constants';
import { ToolbarFiltersService } from '@app/core/services/toolbar-filters.service';
import { ILTCourse } from '@app/models/ilt-course.model';
import { CourseCategoryHelper } from '@app/core/helper/course-category.helper';
import { Datastore } from '@app/core/services/datastore';
import { MultiSelectLocalSearchComponent } from '../multi-select-local-search/multi-select-local-search.component';
import { LearningPath } from '@app/models/learning-path.model';
import { MatSelectChange } from '@angular/material/select';
import { LANGUAGES } from '@app/core/constants/language.constants';
import { Member } from '@app/models/member.model';
import { CourseActivity } from '@app/models/course/course-activity.model';
import { ILTCourseEvent } from '@app/models/ilt-course/ilt-course-event.model';
import { Section } from '@app/core/constants/section.constants';
import { CourseProgressStatus } from '@app/core/pipes/course-progress-status/course-progress-status.pipe';
import { FiltersResources } from '@app/core/interfaces/filters-resources.interface';
import { ILTCourseStatus } from '@app/core/helper/ilt-course-filter-helper';
import { TrainingSchedule } from '@app/models/training-schedule/training-schedule.model';
import { TrainingScheduleCohort } from '@app/models/training-schedule/training-schedule-cohort.model';
import { CustomEmail } from '@app/models/custom-email/custom-email.model';
import { Group } from '@app/models/group.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { TalentStatuses } from '@app/core/constants/talent-statuses.constant';
import { stringArrayEqual } from '@helper/array-helper';
import { LanguageService } from '@app/core/services/language.service';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  CustomDateAdapter,
  MY_FORMATS,
} from '@app/core/helper/custom-date-adapter';
import { MediaMatcher } from '@angular/cdk/layout';
import { FeatureFlagService } from '@app/core/services/feature-flag.service';
import { FEATURES } from '@app/core/constants/features.constant';
import { MemberIdentifier } from '@app/models/member/member-identifier.model';
import { TrainingAvailability } from '@app/core/constants/training-status.constants';

 
@UntilDestroy()
@Component({
  selector: 'app-toolbar-filters',
  templateUrl: './app-toolbar-filters.component.html',
  styleUrls: ['./app-toolbar-filters.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  // eslint-disable-next-line
  standalone: false,
})
export class AppToolbarFiltersComponent implements OnInit, OnChanges {
  @Input() filters: string[] | undefined = [];
  @Input() storeId: string | undefined = '';
  @Input() expandFilters = false;
  @Input() enableLoginUsername = false;

  @Output() doFilters = new EventEmitter<any>();
  @Output() resetFilters = new EventEmitter<any>();
  @Output() filterCounter = new EventEmitter<number>();

  // Server side search
  @ViewChild('courseFilter', { static: false })
  courseFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('iltCourseFilter', { static: false })
  iltCourseFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('learningPathFilter', { static: false })
  learningPathFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('courseActivityFilter', { static: false })
  courseActivityFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('scheduleFilter', { static: false })
  scheduleFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('scheduleCohortsFilter', { static: false })
  scheduleCohortsFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('customEmailNameSearch', { static: false })
  customEmailNameSearch!: MultiSelectServerSideSearchComponent;
  @ViewChild('groupFilter', { static: false })
  groupFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('editorFilter', { static: false })
  editorFilter!: MultiSelectServerSideSearchComponent;

  // Local search
  @ViewChild('courseLocalFilter', { static: false })
  courseLocalFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('iltCourseLocalFilter', { static: false })
  iltCourseLocalFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('learningPathLocalFilter', { static: false })
  learningPathLocalFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('categoryLocalFilter', { static: false })
  categoryLocalFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('memberFilter', { static: false })
  memberFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('memberIdentifierFilter', { static: false })
  memberIdentifierFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('eventsFilter', { static: false })
  eventsFilter!: MultiSelectLocalSearchComponent;

  public Member = Member;
  public MemberIdentifier = MemberIdentifier;
  public Course = Course;
  public ILTCourse = ILTCourse;
  public LearningPath = LearningPath;
  public CourseCategory = CourseCategory;
  public CourseActivity = CourseActivity;
  public ILTCourseEvent = ILTCourseEvent;
  public TrainingSchedule = TrainingSchedule;
  public TrainingScheduleCohort = TrainingScheduleCohort;
  public CustomEmail = CustomEmail;
  public Group = Group;

  public ToolbarFiltersTypes = ToolbarFiltersTypes;
  public TrainingsFilters = TrainingsFilters;
  public sections = Section;
  public CourseProgressStatus = CourseProgressStatus;
  public ILTCourseStatus = ILTCourseStatus;
  public TrainingAvailability = TrainingAvailability;
  public authorChecked = false;

  storedTrainingsFilters: any;
  selectedFilters: any = {};
  allCategories: CourseCategory[] = [];
  filterableEvents: ILTCourseEvent[] = [];
  filterLanguage: string[] | null = null;
  filterCustomEmailsType: string[] = [];
  trainingType = null;
  selectedCourseStatus = [
    CourseProgressStatus.new,
    CourseProgressStatus.running,
  ];
  selectedTrainingStatus = [
    ILTCourseStatus.assigned,
    ILTCourseStatus.subscribed,
  ];
  selectedTrainingAvailabilityStatus: string[] = [TrainingAvailability.ACTIVE];
  filterCount = 0;
  countries = [
    LANGUAGES.DE,
    LANGUAGES.EN,
    LANGUAGES.FR,
    LANGUAGES.ES,
    LANGUAGES.IT,
  ];
  selectedLanguage = 'de';
  filteredTable = '';
  defaultTalentStatus: string[] = [
    TalentStatuses.ACTIVE,
    TalentStatuses.ONBOARDING,
    TalentStatuses.PREBOARDING,
  ];
  talentStatuses = Object.values(TalentStatuses);
  defaultTrainingAvailabilityStatus: string[] = [TrainingAvailability.ACTIVE];
  customEmailTypes = new Map<string, string>();
  startDate: Date | null = null;
  endDate: Date | null = null;
  talentStatusFilter: string[] = [];
  checkUsersWithUsername = false;
  isAuthorOrLearner = false;
  checkedSubunits = false;

  filterResources!: FiltersResources;
  iltEventsResources!: ILTCourseEvent[];
  coursesLocalResources: Course[] = [];
  iLTCoursesLocalResources: ILTCourse[] = [];
  learningPathLocalResources: LearningPath[] = [];
  scheduleCohortFilterId = '';
  mobileQuery: MediaQueryList;

  private isResettingFilters = false;
  private isCategoriesLoading = false;
  private enableApprovalWorkflow = false;

  constructor(
    media: MediaMatcher,
    private storeService: LocalStorageService,
    private datastore: Datastore,
    private toolbarFiltersService: ToolbarFiltersService,
    private datePipe: DatePipe,
    private languageService: LanguageService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 960px)');
  }

  ngOnInit(): void {
    this.initFilters();
    this.startFilterServiceListener();
    this.setEnableApprovalWorkflow();
    this.selectedLanguage = this.languageService.getCurrentLanguage();
  }

  ngOnChanges(): void {
    if (
      this.storeId === TrainingsFilters.instructorsFilters &&
      this.iltCourseFilter?.initialResources.length > 0
    ) {
      setTimeout(() => {
        this.iltCourseFilter.loadResources();
      }, 0);
    }

    if (this.storeId === TrainingsFilters.emailTemplatesFilters) {
      this.customEmailTypes.set('user', 'main.profile.course.tabControl.user');
      this.customEmailTypes.set('course', 'sections.courses');
      this.customEmailTypes.set('learning_path', 'filters.learningPath');
      this.customEmailTypes.set('live_training', 'sections.iltCourse');
      this.customEmailTypes.set(
        'reminder',
        'main.profile.course.tabSettings.reminderBtn',
      );
      this.customEmailTypes.set('task', 'filters.task');
    }
    if (this.storeId === TrainingsFilters.peoplePeopleFilters) {
      this.talentStatusFilter = this.defaultTalentStatus;
    }
    if (
      [
        TrainingsFilters.coursesFilters,
        TrainingsFilters.learningPathsFilters,
        TrainingsFilters.trainingsFilters,
        TrainingsFilters.trainingScheduleFilters,
        TrainingsFilters.orgaTrainingFilters,
      ].includes(this.storeId as TrainingsFilters)
    ) {
      this.selectedTrainingAvailabilityStatus =
        this.defaultTrainingAvailabilityStatus;
    }

    this.initFilters();
  }

  onCourseFilter(resources: Resource[]): void {
    this.doFilterCourses(resources as Course[]);
  }

  onIltFilter(resources: Resource[]): void {
    this.doFilterIlts(resources as ILTCourse[]);
  }

  onIltLocalFilter(iltIds: string[]): void {
    this.doLocalFilterIlts(iltIds);
  }

  doFilterEvents(ids: string[]): void {
    if (this.isResettingFilters) {
      return;
    }
    this.selectedFilters.ilt_course_event_ids = ids;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onFilterLanguage(languages: MatSelectChange): void {
    if (this.isResettingFilters) {
      return;
    }

    this.filterLanguage = languages.value;
    this.selectedFilters.language = languages.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onLPFilter(resources: Resource[]): void {
    this.doFilterLP(resources as LearningPath[]);
  }

  onFilterCourseActivities(resources: Resource[]): void {
    if (this.isResettingFilters) {
      return;
    }
    const courseActivities = resources as CourseActivity[];
    if (
      !this.selectedFilters.course_activity_ids ||
      !stringArrayEqual(
        this.selectedFilters.course_activity_ids,
        courseActivities.map(r => r.id),
      )
    ) {
      this.selectedFilters.course_activity_ids = courseActivities.map(
        r => r.id,
      );
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  onFilterMembers(resources: Resource[]): void {
    if (this.isResettingFilters) {
      return;
    }
    const members = resources as Member[];
    if (
      !this.selectedFilters.member_ids ||
      !stringArrayEqual(
        this.selectedFilters.member_ids,
        members.map(m => m.id),
      )
    ) {
      this.selectedFilters.member_ids = members.map(r => r.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  onFilterMemberIdentifiers(resources: Resource[]): void {
    if (this.isResettingFilters) {
      return;
    }
    const members = resources as Member[];
    if (
      !this.selectedFilters.member_identifiers ||
      !stringArrayEqual(
        this.selectedFilters.member_identifiers,
        members.map(m => m.id),
      )
    ) {
      this.selectedFilters.member_identifiers = members.map(r => r.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  onFilterEditors(resources: Resource[]): void {
    if (this.isResettingFilters) {
      return;
    }
    const members = resources as Member[];
    if (
      !this.selectedFilters.editor_ids ||
      !stringArrayEqual(
        this.selectedFilters.editor_ids,
        members.filter(m => !!m).map(m => m.id),
      )
    ) {
      this.selectedFilters.editor_ids = members.filter(m => !!m).map(m => m.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  onFilterTrainingSchedules(resources: Resource[]): void {
    if (this.isResettingFilters) {
      return;
    }
    const trainingSchedules = resources as TrainingSchedule[];
    if (
      !this.selectedFilters.schedule_ids ||
      !stringArrayEqual(
        this.selectedFilters.schedule_ids,
        trainingSchedules.map(schedule => schedule.id),
      )
    ) {
      this.selectedFilters.schedule_ids = trainingSchedules.map(r => r.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  onFilterTrainingScheduleCohorts(resources: Resource[]): void {
    if (this.isResettingFilters) {
      return;
    }
    const trainingScheduleCohorts = resources as TrainingScheduleCohort[];
    if (
      !this.selectedFilters.cohort_ids ||
      !stringArrayEqual(
        this.selectedFilters.cohort_ids,
        trainingScheduleCohorts.map(cohort => cohort.id),
      )
    ) {
      this.selectedFilters.cohort_ids = trainingScheduleCohorts.map(r => r.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  onCustomEmailFilter(resources: Resource[]): void {
    if (this.isResettingFilters) {
      return;
    }
    const customEmails = resources as CustomEmail[];
    if (
      !this.selectedFilters.email_ids ||
      !stringArrayEqual(
        this.selectedFilters.email_ids,
        customEmails.map(email => email.id),
      )
    ) {
      this.selectedFilters.email_ids = customEmails.map(r => r.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  onGroupFilter(resources: Resource[]): void {
    if (this.isResettingFilters) {
      return;
    }
    const groups = resources as Group[];
    if (
      !this.selectedFilters.group_ids ||
      !stringArrayEqual(
        this.selectedFilters.group_ids,
        groups.map(group => group.id),
      )
    ) {
      this.selectedFilters.group_ids = groups.map(r => r.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  onTrainingType(type: MatSelectChange): void {
    if (this.isResettingFilters) {
      return;
    }
    this.trainingType = type.value;
    this.selectedFilters.trainingType = type.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onCourseStatusChanged(status: MatSelectChange): void {
    if (this.isResettingFilters) {
      return;
    }
    this.selectedCourseStatus = status.value;
    this.selectedFilters.course_status = status.value.join(',');
    this.selectedFilters.selectedStatus = status.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onTrainingStatusChanged(status: MatSelectChange): void {
    if (this.isResettingFilters) {
      return;
    }
    this.selectedTrainingStatus = status.value;
    this.selectedFilters.selectedStatus = status.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onTrainingAvailabilityStatusChanged(status: MatSelectChange): void {
    if (this.isResettingFilters) {
      return;
    }
    this.selectedTrainingAvailabilityStatus = status.value;
    this.selectedFilters.state = status.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doFilterCourses(courses: Course[]): void {
    if (this.isResettingFilters) {
      return;
    }
    if (
      !this.selectedFilters.course_ids ||
      !stringArrayEqual(
        this.selectedFilters.course_ids,
        courses.map(r => r.id),
      )
    ) {
      this.selectedFilters.course_ids = courses.map(c => c.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  doLocalFilterCourses(coursesIds: string[]): void {
    if (this.isResettingFilters) {
      return;
    }
    this.selectedFilters.course_ids = coursesIds;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doFilterIlts(ilts: ILTCourse[]): void {
    if (this.isResettingFilters) {
      return;
    }
    if (
      !this.selectedFilters.ilt_course_ids ||
      !stringArrayEqual(
        this.selectedFilters.ilt_course_ids,
        ilts.map(ilt => ilt.id),
      )
    ) {
      this.selectedFilters.ilt_course_ids = ilts.map(c => c.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
    if (this.filters?.includes(ToolbarFiltersTypes.trainingsEvents)) {
      this.filterableEvents = [];
      ilts.forEach(ilt => {
        if (ilt.iltCourseEvents) {
          this.filterableEvents = this.filterableEvents.concat(
            ilt.iltCourseEvents,
          );
        }
      });
    }
  }

  doLocalFilterIlts(ilts: string[]): void {
    if (this.isResettingFilters) {
      return;
    }
    this.selectedFilters.ilt_course_ids = ilts;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doFilterLP(lps: LearningPath[]): void {
    if (this.isResettingFilters) {
      return;
    }
    if (
      !this.selectedFilters.learning_path_ids ||
      !stringArrayEqual(
        this.selectedFilters.learning_path_ids,
        lps.map(lp => lp.id),
      )
    ) {
      this.selectedFilters.learning_path_ids = lps.map(c => c.id);
      this.updateCounter(this.selectedFilters);
      this.doFilters.emit(this.selectedFilters);
    }
  }

  doFilterLPLocal(lpsIds: string[]): void {
    if (this.isResettingFilters) {
      return;
    }
    this.selectedFilters.learning_path_ids = lpsIds;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doFilterCategories(courseCategories: string[]): void {
    if (this.isResettingFilters) {
      return;
    }
    this.selectedFilters.course_category_ids = courseCategories;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onAuthorToggleChange($event: MatSlideToggleChange): void {
    this.selectedFilters.authorChecked = $event.checked || undefined;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onEmailTypeFilter(type: MatSelectChange): void {
    if (this.isResettingFilters) {
      return;
    }
    this.filterCustomEmailsType = type.value;
     
    this.selectedFilters.email_types = this.filterCustomEmailsType.map(
      (value: any) => value.key,
    );
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onHireStartDateFilter(event: MatDatepickerInputEvent<Date>): void {
    if (this.isResettingFilters) {
      return;
    }
    const startDate = this.datePipe.transform(event.value, 'yyyy/MM/dd');
    if (startDate) {
      this.startDate = new Date(startDate);
    }
    this.selectedFilters.after_hire_date = startDate;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onHireEndDateFilter(event: MatDatepickerInputEvent<Date>): void {
    if (this.isResettingFilters) {
      return;
    }
    const endDate = this.datePipe.transform(event.value, 'yyyy/MM/dd');
    if (endDate) {
      this.endDate = new Date(endDate);
    }
    this.selectedFilters.before_hire_date = endDate;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onTalentStatusFilter(status: MatSelectChange): void {
    if (this.isResettingFilters) {
      return;
    }
    this.selectedFilters.status = status.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  changeCheckUsersWithUsername(event: MatSlideToggleChange): void {
    this.checkUsersWithUsername = event.checked;
    this.selectedFilters.username = event.checked;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onMemberNameChange(): void {
    if (this.isResettingFilters) {
      return;
    }
     
    this.selectedFilters.full_name = this.filteredTable;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onToggleSubunits(event: MatSlideToggleChange): void {
    this.checkedSubunits = event.checked;
    this.selectedFilters.checkedSubunits = event.checked;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onResetFilters(emitReset = true): void {
    this.isResettingFilters = true;
    this.authorChecked = false;
    if (this.courseFilter) {
      this.courseFilter.multiSelect.value = [];
    }
    if (this.courseLocalFilter) {
      this.courseLocalFilter.multiSelect.value = [];
    }
    if (this.iltCourseLocalFilter) {
      this.iltCourseLocalFilter.multiSelect.value = [];
    }
    if (this.categoryLocalFilter) {
      this.categoryLocalFilter.multiSelect.value = [];
    }
    if (this.iltCourseFilter) {
      this.iltCourseFilter.multiSelect.value = [];
    }
    if (this.learningPathFilter) {
      this.learningPathFilter.multiSelect.value = [];
    }
    if (this.learningPathLocalFilter) {
      this.learningPathLocalFilter.multiSelect.value = [];
    }
    if (this.courseActivityFilter) {
      this.courseActivityFilter.multiSelect.value = [];
    }
    if (this.memberFilter) {
      this.memberFilter.multiSelect.value = [];
    }
    if (this.memberIdentifierFilter) {
      this.memberIdentifierFilter.multiSelect.value = [];
    }
    if (this.editorFilter) {
      this.editorFilter.multiSelect.value = [];
    }
    if (this.eventsFilter) {
      this.eventsFilter.multiSelect.value = [];
    }
    if (this.scheduleFilter) {
      this.scheduleFilter.multiSelect.value = [];
    }
    if (this.scheduleCohortsFilter) {
      this.scheduleCohortsFilter.multiSelect.value = [];
    }
    if (this.customEmailNameSearch) {
      this.customEmailNameSearch.multiSelect.value = [];
    }
    if (this.groupFilter) {
      this.groupFilter.multiSelect.value = [];
    }
    if (this.filterLanguage) {
      this.filterLanguage = [];
    }
    if (this.filterCustomEmailsType) {
      this.filterCustomEmailsType = [];
    }

    this.selectedCourseStatus = [
      CourseProgressStatus.new,
      CourseProgressStatus.running,
    ];
    this.selectedTrainingStatus = [
      ILTCourseStatus.assigned,
      ILTCourseStatus.subscribed,
    ];
    if (this.storeId === TrainingsFilters.orgaMemberFilters) {
      this.talentStatusFilter = [];
    } else {
      this.talentStatusFilter = this.defaultTalentStatus;
    }
    this.checkUsersWithUsername = false;
    this.selectedFilters = {};
    this.trainingType = null;
    this.filterCount = 0;
    this.filterableEvents = [];
    this.startDate = new Date('');
    this.endDate = new Date('');
    this.filteredTable = '';
    this.checkedSubunits = false;

    this.isResettingFilters = false;

    if (
      this.storeId === TrainingsFilters.myCoursesFilters ||
      this.storeId === TrainingsFilters.myLearningPathsFilters ||
      this.storeId === TrainingsFilters.trainingCatalogueFilters
    ) {
       
      this.selectedFilters.selectedStatus = this.selectedCourseStatus;
      this.filterCount = 1;
    }

    if (this.storeId === TrainingsFilters.myIltCoursesFilters) {
      this.selectedFilters.selectedStatus = this.selectedTrainingStatus;
      this.filterCount = 1;
    }

    if (this.storeId === TrainingsFilters.peoplePeopleFilters) {
      this.talentStatusFilter = this.defaultTalentStatus;
      this.selectedFilters.status = this.talentStatusFilter;
      this.filterCount = 1;
    }

    if (
      [
        TrainingsFilters.coursesFilters,
        TrainingsFilters.learningPathsFilters,
        TrainingsFilters.trainingsFilters,
        TrainingsFilters.trainingScheduleFilters,
        TrainingsFilters.orgaTrainingFilters,
      ].includes(this.storeId as TrainingsFilters)
    ) {
      this.selectedTrainingAvailabilityStatus =
        this.defaultTrainingAvailabilityStatus;
      this.selectedFilters.state = this.selectedTrainingAvailabilityStatus;
      this.filterCount = 1;
    }

    this.filterCounter.emit(this.filterCount);
    if (emitReset) {
      this.resetFilters.emit();
    }
  }

  compareEmailTypes(
    option: Record<string, string>,
    selection: Record<string, string>,
  ): boolean {
    return option.key === selection.key;
  }

  getEditorInitialFilters(): Record<string, boolean> {
    if (this.storeId === TrainingsFilters.coursesFilters) {
      return {
        course_editors: true,
      };
    } else if (this.storeId === TrainingsFilters.learningPathsFilters) {
      return {
        learning_path_editors: true,
      };
    } else if (this.storeId === TrainingsFilters.trainingsFilters) {
      return {
        ilt_course_editors: true,
      };
    } else if (this.storeId === TrainingsFilters.trainingScheduleFilters) {
      return {
        schedule_editors: true,
      };
    } else {
      return {};
    }
  }

  private setInitialFilters(storeItem: string): void {
    this.storedTrainingsFilters = this.storeService.getItem(storeItem);
    if (!this.storedTrainingsFilters?.filter) {
      this.onResetFilters(false);
      return;
    }
    if (this.editorFilter) {
      this.editorFilter.multiServerSideCtrl.setValue(undefined, {
        emitEvent: false,
      });
    }
    if (this.editorFilter) {
      this.editorFilter.multiServerSideCtrl.setValue(undefined, {
        emitEvent: false,
      });
    }
    if (
      this.storedTrainingsFilters?.filter?.author ||
      this.storedTrainingsFilters?.filter?.own
    ) {
      this.authorChecked = true;
      this.selectedFilters.author = this.authorChecked;
    } else {
      this.authorChecked = false;
    }
    this.filterLanguage =
      this.storedTrainingsFilters.filter?.language ||
      this.storedTrainingsFilters.filter?.email_language ||
      null;
    if (this.filterLanguage) {
      this.selectedFilters.language = this.filterLanguage;
    }
    if (this.storedTrainingsFilters.filter?.before_hire_date) {
      this.endDate = new Date(
        this.storedTrainingsFilters.filter.before_hire_date,
      );
    }
    if (this.storedTrainingsFilters.filter?.after_hire_date) {
      this.startDate = new Date(
        this.storedTrainingsFilters.filter.after_hire_date,
      );
    }
    if (this.storedTrainingsFilters.filter?.username) {
      this.checkUsersWithUsername = true;
    }
    if (this.storedTrainingsFilters?.filter?.status) {
      this.talentStatusFilter = this.storedTrainingsFilters?.filter?.status;
    }
    if (this.storedTrainingsFilters?.filter?.selectedStatus) {
      this.selectedTrainingStatus =
        this.storedTrainingsFilters.filter.selectedStatus;
    }
    if (this.storedTrainingsFilters?.filter?.course_status) {
      this.selectedCourseStatus =
        this.storedTrainingsFilters.filter.course_status.split(',');
    } else if (this.storedTrainingsFilters?.filter?.learning_path_status) {
      this.selectedCourseStatus =
        this.storedTrainingsFilters.filter.learning_path_status.split(',');
    }
    if (this.storedTrainingsFilters?.filter?.group_ids) {
      this.storedTrainingsFilters.filter.group_ids =
        this.storedTrainingsFilters.filter.group_ids;
    } else {
      this.storedTrainingsFilters.filter.group_ids = [];
    }
    if (this.storedTrainingsFilters?.filter?.org_ids) {
      this.checkedSubunits =
        this.storedTrainingsFilters.filter.org_ids?.length > 1;
    } else {
      this.checkedSubunits = false;
    }
    if (this.storedTrainingsFilters?.filter?.full_name) {
      this.filteredTable = this.storedTrainingsFilters.filter.full_name;
    }
    if (this.storedTrainingsFilters?.filter?.trainingType) {
      this.trainingType = this.storedTrainingsFilters.filter.trainingType;
    }
    if (this.storedTrainingsFilters?.filter?.ilt_course_ids) {
      this.storedTrainingsFilters.filter.ilt_course_ids =
        this.storedTrainingsFilters.filter.ilt_course_ids;
    } else {
      this.storedTrainingsFilters.filter.ilt_course_ids = [];
    }
    if (this.storedTrainingsFilters?.filter?.ilt_course_event_ids) {
      this.storedTrainingsFilters.filter.ilt_course_event_ids =
        this.storedTrainingsFilters.filter.ilt_course_event_ids;
    } else {
      this.storedTrainingsFilters.filter.ilt_course_event_ids = [];
    }

    if (this.storedTrainingsFilters?.filter?.training_type) {
      this.trainingType = this.storedTrainingsFilters.filter.training_type;
    }

    if (this.storedTrainingsFilters?.filter?.state) {
      this.selectedTrainingAvailabilityStatus =
        this.storedTrainingsFilters?.filter?.state;
    }

    if (this.storedTrainingsFilters?.filter) {
      this.selectedFilters = this.storedTrainingsFilters?.filter;
      this.updateCounter(this.storedTrainingsFilters?.filter);
    }
  }

  private initFilters(): void {
    if (this.storeId) {
      this.setInitialFilters(this.storeId);
    }
    this.loadCategories();
  }

  private loadCategories(): void {
    if (
      this.filters?.includes(ToolbarFiltersTypes.categories) &&
      !this.allCategories.length
    ) {
      this.setAllCategories();
    }
  }

  private setAllCategories(): void {
    if (!this.isCategoriesLoading) {
      this.isCategoriesLoading = true;
      this.datastore
        .findAll(CourseCategory, {
          page: {
            size: 250,
          },
        })
        .pipe(take(1))
        .subscribe(categories => {
          this.allCategories = CourseCategoryHelper.sortCourseCategories(
            categories.getModels(),
          );
          this.isCategoriesLoading = false;
        });
    }
  }

  private updateCounter(filters: any): void {
    let currentCount = Object.keys(filters).filter(
      key =>
        filters[key] !== null &&
        ((typeof filters[key] === 'object' && filters[key].length !== 0) ||
          (typeof filters[key] !== 'object' &&
            typeof filters[key] !== 'undefined' &&
            filters[key] !== false)),
    ).length;
    if (
      (filters.hasOwnProperty('after_hire_date') &&
        filters.hasOwnProperty('before_hire_date')) ||
      (filters.hasOwnProperty('owner') && !this.isAuthorOrLearner) ||
      (filters.hasOwnProperty('own') &&
        this.storeId !== TrainingsFilters.trainingScheduleFilters) ||
      filters.hasOwnProperty('show_in_catalog') ||
      (filters.hasOwnProperty('author') &&
        filters.hasOwnProperty('authorChecked')) ||
      (filters.hasOwnProperty('own') &&
        filters.hasOwnProperty('authorChecked')) ||
      (filters.hasOwnProperty('author') && this.isAuthorOrLearner) ||
      (filters.hasOwnProperty('owner') && this.isAuthorOrLearner) ||
      filters.hasOwnProperty('schedule_id') ||
      (filters.hasOwnProperty('full_name') && filters.full_name.length === 0) ||
      (filters.hasOwnProperty('org_ids') && filters.org_ids.length === 1)
    ) {
      currentCount--;
    }
    if (
      (filters.hasOwnProperty('course_status') &&
        filters.hasOwnProperty('selectedStatus')) ||
      (filters.hasOwnProperty('course_status') &&
        filters.hasOwnProperty('learning_path_status')) ||
      (filters.hasOwnProperty('trainingType') &&
        filters.trainingType?.length === 3)
    ) {
      currentCount--;
    }

    if (
      (filters.hasOwnProperty('course_status') &&
        filters.hasOwnProperty('selectedStatus') &&
        filters.hasOwnProperty('learning_path_status')) ||
      filters.hasOwnProperty('unit_id')
    ) {
      currentCount--;
    }

    if (
      this.storeId === TrainingsFilters.peoplePeopleFilters &&
      filters.hasOwnProperty('visible')
    ) {
      currentCount--;
    }

    if (currentCount !== this.filterCount) {
      this.filterCount = currentCount;
      this.filterCounter.emit(this.filterCount);
    }
  }

  private startFilterServiceListener(): void {
    this.toolbarFiltersService
      .getFilterOverwrite()
      .pipe(untilDestroyed(this))
      .subscribe((resource: FiltersResources) => {
        this.filterResources = resource;
        switch (this.filterResources?.storeId) {
          case TrainingsFilters.instructorsFilters:
            if (resource.type === ToolbarFiltersTypes.trainingsEvents) {
              this.iltEventsResources = resource.resources as ILTCourseEvent[];
            }
            if (resource.type === ToolbarFiltersTypes.trainingsLocal) {
              this.iLTCoursesLocalResources = resource.resources as ILTCourse[];
            }
            break;
          case TrainingsFilters.myIltCoursesFilters:
            if (resource.type === ToolbarFiltersTypes.trainingsLocal) {
              this.iLTCoursesLocalResources = resource.resources as ILTCourse[];
            }
            if (resource.type === ToolbarFiltersTypes.categories) {
              this.allCategories = resource.resources as CourseCategory[];
            }
            break;
          case TrainingsFilters.myLearningPathsFilters:
            if (resource.type === ToolbarFiltersTypes.learningPathsLocal) {
              this.learningPathLocalResources =
                resource.resources as LearningPath[];
            }
            if (resource.type === ToolbarFiltersTypes.categories) {
              this.allCategories = resource.resources as CourseCategory[];
            }
            break;
          case TrainingsFilters.myCoursesFilters:
          case TrainingsFilters.trainingCatalogueFilters:
            if (resource.type === ToolbarFiltersTypes.coursesLocal) {
              this.coursesLocalResources = resource.resources as Course[];
            }
            if (resource.type === ToolbarFiltersTypes.trainingsLocal) {
              this.iLTCoursesLocalResources = resource.resources as ILTCourse[];
            }
            if (resource.type === ToolbarFiltersTypes.learningPathsLocal) {
              this.learningPathLocalResources =
                resource.resources as LearningPath[];
            }
            if (resource.type === ToolbarFiltersTypes.categories) {
              this.allCategories = resource.resources as CourseCategory[];
            }
            break;
          case TrainingsFilters.trainingScheduleCohortsFilters:
            if (resource.type === ToolbarFiltersTypes.cohorts) {
              this.scheduleCohortFilterId = resource.resources as string;
            }
            break;
          case TrainingsFilters.coursesFilters:
          case TrainingsFilters.trainingsFilters:
          case TrainingsFilters.learningPathsFilters:
          case TrainingsFilters.trainingScheduleFilters:
            if (resource.type === ToolbarFiltersTypes.author) {
              this.isAuthorOrLearner = resource.resources as boolean;
            }
            break;
        }
        if (this.storeId) {
          this.storedTrainingsFilters = this.storeService.getItem(this.storeId);
        }
      });
  }

  private setEnableApprovalWorkflow(): void {
    this.featureFlagService
      .isFeatureFlagEnabled(FEATURES.APPROVAL_WORKFLOW)
      .pipe(take(1))
      .forEach(value => {
        this.enableApprovalWorkflow = value;
        if (this.enableApprovalWorkflow) {
          this.customEmailTypes.set('assignment', 'common.assignments');
        }
      });
  }
}
 
