<div *ngIf="!!url;then with_link else without_link"></div>
<ng-template #with_link>
  <app-elearnio-mat-table-link
    [text]="text"
    [url]="url"
    [state]="state"
  ></app-elearnio-mat-table-link>
</ng-template>

<ng-template #without_link>
  <span class="without-link" [class.archived]="state === TrainingAvailability.ARCHIVED">
    <mat-icon *ngIf="state === TrainingAvailability.ARCHIVED" class="size-sm">archive</mat-icon>
    <span>{{ text }}</span>
  </span>
</ng-template>
