/* tslint:disable:no-any */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class ProgressComponent implements OnInit {
  @Input() value = 0;
  @Input() height = 20;
  @Input() width: string | number = 100;
  @Input() showValue = true;
  @Input() allGreen = false;
  @Input() allRed = false;
  @Input() color = '';

  id = new Date().getTime();

  constructor() {}

  ngOnInit(): void {}

  calcSize(): object {
    let unit = 'px';
    if (typeof this.width === 'number') {
      unit = '';
    }
    return {
      height: `${this.height}px`,
      width: `${this.width}${unit}`,
    };
  }
}
