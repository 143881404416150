import { Component, Input } from '@angular/core';
import { TrainingAvailability } from '@app/core/constants/training-status.constants';

@Component({
  selector: 'app-elearnio-mat-table-link',
  templateUrl: './elearnio-mat-table-link.component.html',
  styleUrls: ['./elearnio-mat-table-link.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class ElearnioMatTableLinkComponent {
  @Input() text = '';
  @Input() url = '';
  @Input() state = '';
  @Input() queryParams!: any;

  TrainingAvailability = TrainingAvailability;

  constructor() {}

  trimText(str: string): string {
    return str.substring(0, 80) + '...';
  }
}
