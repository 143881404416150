import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  fromEvent,
} from 'rxjs';
import {
  debounceTime,
  tap,
  filter,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { JsonApiQueryData, JsonApiModel } from '@elearnio/angular2-jsonapi';
import { MatOption } from '@angular/material/core';

import { Datastore } from '@services/datastore';
import { Resource, MultiSelectPosition } from './interfaces';
import { Member } from '@models/member.model';
import { Group } from '@models/group.model';
import { CourseCategory } from '@models/course-category.model';
import { Course } from '@models/course.model';
import { stringArrayEqual } from '@helper/array-helper';
import { Country } from '@models/country.model';
import { MemberService } from '@services/member.service';
import { ILTCourse } from '@app/models/ilt-course.model';
import { LearningPath } from '@app/models/learning-path.model';
import { OrganizationLevel } from '@models/organization/organization-level.model';
import { ILTCourseEvent } from '@app/models/ilt-course/ilt-course-event.model';
import { multiSelectCenterBottomTopPosition } from '@core/constants/multi-select-position.constant';
import { CertificateTemplate } from '@app/models/template/certificate-template.model';
import { MemberTrimmed } from '@app/models/member/member-trimmed.model';
import { MemberIdentifier } from '@app/models/member/member-identifier.model';
import { CourseActivity } from '@app/models/course/course-activity.model';
import { TrainingSchedule } from '@app/models/training-schedule/training-schedule.model';
import { CustomEmail } from '@app/models/custom-email/custom-email.model';
import { TrainingScheduleCohort } from '@app/models/training-schedule/training-schedule-cohort.model';

@Component({
  selector: 'app-multi-select-server-side-search',
  templateUrl: './multi-select-server-side-search.component.html',
  styleUrls: ['./multi-select-server-side-search.component.scss'],
  // eslint-disable-next-line
  standalone: false,
})
export class MultiSelectServerSideSearchComponent
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild('multiSelect', { static: true }) multiSelect!: MatSelect;
  @Input() initialIds: string[] = [];
  @Input() extraOption = '';
  @Input() excludeIds: string[] = [];
  @Input() className!: any;
  @Input() placeholder = '';
  @Input() placeholderLabel = '';
  @Input() noEntriesFoundLabel = '';
  @Input() includes = '';
  @Input() resultsLimit = 10;
  @Input() multiSelectPosition: MultiSelectPosition =
    multiSelectCenterBottomTopPosition;
  @Input() infiniteScroll = true;
  @Input() multiple = true;
  @Input() disabled = false;
  @Input() disableBackground = false;
  @Input() required = false;
  @Input() hideInitialResources = false;
  @Input() showWarning = false;
  @Input() preventInitialEmitEvent = false;
  @Input() showDetails = true;
  @Input() sizeSmall = false;
  @Input() sizeRegular = false;
  @Input() initialFilters: {
    [key: string]: string | boolean | string[] | undefined;
  } = {};
  @Output() selectEvent = new EventEmitter<Resource[]>();
  @Output() dropDownEvent = new EventEmitter<boolean>();

  public multiServerSideCtrl: UntypedFormControl = new UntypedFormControl();
  public multiServerSideFilteringCtrl: UntypedFormControl =
    new UntypedFormControl();
  public searching = false;
  public filteredServerSideResources: ReplaySubject<Resource[]> =
    new ReplaySubject<Resource[]>(1);
  public initialResources: Resource[] = [];
  MemberIdentifier = MemberIdentifier;

  protected onDestroy = new Subject<void>();

  private pageNumber = 1;
  private maxPageCount = 1;
  private initialMaxPageCount = 1;
  private filters:
    | { [key: string]: string | boolean | string[] | undefined }
    | undefined = undefined;
  private displayedResources: Resource[] = [];
  private previousSelectedResources: Resource[] = [];
  private openChangeSubscribe!: Subscription;

  constructor(
    private datastore: Datastore,
    private memberService: MemberService,
    public elementRef: ElementRef,
  ) {}

  setPanelChangeEvent(): void {
    if (this.openChangeSubscribe) {
      this.openChangeSubscribe.unsubscribe();
    }

    this.openChangeSubscribe = this.multiSelect.openedChange
      .pipe(take(1))
      .subscribe(() => {
        this.loadResources();
      });
  }

  registerInfiniteScrollEvent(): void {
    if (!this.infiniteScroll) {
      return;
    }

    this.setPanelChangeEvent();

    this.multiSelect.openedChange
      .pipe(
        filter(isOpened => !!isOpened),
        switchMap(() =>
          fromEvent(this.multiSelect._elementRef.nativeElement, 'scroll').pipe(
            debounceTime(200),
          ),
        ),
        takeUntil(this.onDestroy),
      )
      .subscribe((event: any) => {
        this.addMoreResourcesOnScrollEnd(event);
      });
  }

  ngOnInit(): void {
    this.registerInfiniteScrollEvent();

    this.multiServerSideCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(resources => {
        resources = Array.isArray(resources) ? resources : [resources];
        /* Workaround from value changing twice due to restoring
        of values which are not in the current filter */
        let restoreSelectedValues = false;
        const optionValues = this.multiSelect.options.map(
          option => option.value,
        );
        this.previousSelectedResources.forEach(previousValue => {
          if (
            this.multiple &&
            previousValue &&
            !resources.some((v: Resource) =>
              this.multiSelect.compareWith(v, previousValue),
            ) &&
            !optionValues.some(v =>
              this.multiSelect.compareWith(v, previousValue),
            )
          ) {
            restoreSelectedValues = true;
          }
        });
        if (!restoreSelectedValues) {
          this.previousSelectedResources = resources;
          this.selectEvent.emit(resources);
        }
      });

    this.multiServerSideFilteringCtrl.valueChanges
      .pipe(
        debounceTime(200),
        filter(search => {
          this.pageNumber = 1;
          if (!search) {
            this.maxPageCount = this.initialMaxPageCount;
            this.filters = this.initialFilters;
            let displayedResources: Resource[] = [];
            if (this.multiServerSideCtrl.value) {
              const value = Array.isArray(this.multiServerSideCtrl.value)
                ? this.multiServerSideCtrl.value
                : [this.multiServerSideCtrl.value];
              value.forEach((r: Resource) => {
                if (
                  !this.initialResources.find(
                    dR => dR.id?.toString() === r.id?.toString(),
                  )
                ) {
                  displayedResources.push(r);
                }
              });
            }
            displayedResources = displayedResources.concat(
              this.initialResources,
            );
            this.displayedResources = displayedResources.filter(
              r => !this.excludeIds.includes(r.id),
            );
            this.filteredServerSideResources.next(this.displayedResources);
          }
          return !!search;
        }),
        tap(() => (this.searching = true)),
        takeUntil(this.onDestroy),
        switchMap((search: string) => {
          const filters = Object.assign(
            {},
            this.initialFilters,
            this.isMemberClass()
              ?  
                { full_name: search }
              : this.className === MemberIdentifier
              ?  
                { member_identifier: search }
              : this.className === ILTCourseEvent
              ? {
                   
                  event_name: search,
                }
              : { name: search },
          );
          this.filters = filters;
          this.setNotIds(filters);

          return this.datastore.findAll(this.className, {
            filter: filters,
            page: {
               
              number: this.pageNumber,
              size: this.resultsLimit,
            },
            include: this.includes || undefined,
            sort: this.getSort(),
          });
        }),
        takeUntil(this.onDestroy),
      )
      .subscribe(
        filteredResources => {
          this.searching = false;
           
          this.maxPageCount = filteredResources.getMeta()?.meta.page_count || 1;
          this.displayedResources = filteredResources.getModels() as Resource[];
          this.displayedResources = this.displayedResources.filter(
            r => !this.excludeIds.includes(r.id),
          );
          this.filteredServerSideResources.next(this.displayedResources);
        },
        () => {
          this.searching = false;
        },
      );
  }

  loadResources(): void {
    this.setInitialValue();

    this.filteredServerSideResources
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: Resource, b: Resource) =>
          a && b && a.id?.toString() === b.id?.toString();
      });

    if (this.multiSelectPosition) {
       
      this.multiSelect._positions = [
        {
          originX: this.multiSelectPosition.originX,
          originY: this.multiSelectPosition.originY,
          overlayX: this.multiSelectPosition.overlayX,
          overlayY: this.multiSelectPosition.overlayY,
        },
      ];
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getName(resource: Resource): string {
    if (this.showDetails && this.isMemberClass()) {
      return resource.name + ' (' + resource.identifier + ')';
    } else if (this.className === MemberIdentifier) {
      return resource.identifier;
    }
    return resource.name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['initialIds'] &&
      this.initialIds &&
      this.initialIds.length &&
      typeof this.initialIds[0] !== 'undefined' &&
      (!changes['initialIds'].previousValue ||
        !stringArrayEqual(
          changes['initialIds'].currentValue,
          changes['initialIds'].previousValue,
        ))
    ) {
      if (!this.hideInitialResources) {
        this.getInitialSelected()
          .pipe(take(1))
          .subscribe(selected => {
            const selectedResources = (selected?.getModels() ||
              []) as Resource[];
            const preventEmitEvent =
              this.preventInitialEmitEvent && changes['initialIds'].firstChange;
            this.setInitialSelected(
              this.initialResources,
              selectedResources,
              !preventEmitEvent,
            );
          });
      }
    } else if (
      changes['initialIds'] &&
      changes['initialIds'].currentValue?.length === 0
    ) {
      this.multiServerSideCtrl.setValue([]);
    }

    if (
      changes['initialFilters'] &&
      JSON.stringify(changes.initialFilters.currentValue) !==
        JSON.stringify(changes.initialFilters.previousValue)
    ) {
      this.pageNumber = 1;
      this.maxPageCount = 1;
      this.filters = this.initialFilters;
      this.initialResources = [];
      this.setInitialValue();
    }
  }

  setInitialSelected(
    initialResources: Resource[],
    selectedResources: Resource[],
    emitEvent = true,
  ): void {
    if (selectedResources.length) {
      initialResources = initialResources.filter(
        r =>
          !selectedResources.find(sR => sR.id?.toString() === r.id?.toString()),
      );
      this.initialResources = selectedResources.concat(
        initialResources,
      ) as Resource[];

      this.multiServerSideCtrl.setValue(
        this.multiple ? selectedResources : selectedResources[0],
        {
          emitEvent,
        },
      );
    } else {
      initialResources = initialResources.filter(
        r =>
          !this.initialResources.find(
            sR => sR.id?.toString() === r.id?.toString(),
          ),
      );
      this.initialResources = this.initialResources.concat(
        initialResources,
      ) as Resource[];
    }
    this.displayedResources = this.initialResources.filter(
      r => !this.excludeIds.includes(r.id),
    );
    this.filteredServerSideResources.next(this.displayedResources);
  }

  panelChange(panelOpen: boolean): void {
    this.dropDownEvent.emit(panelOpen);
  }

  private setInitialValue() {
    const filters = { ...this.initialFilters };
    this.setNotIds(filters);
    this.datastore
      .findAll(this.className, {
        filter: filters,
        page: {
           
          number: this.pageNumber,
          size: this.resultsLimit,
        },
        include: this.includes || undefined,
        sort: this.getSort(),
      })
      .pipe(take(1))
      .subscribe(initial => {
        const initialResources = initial.getModels() as Resource[];
         
        this.maxPageCount = initial.getMeta()?.meta.page_count || 1;
        this.initialMaxPageCount = this.maxPageCount;
        this.setInitialSelected(
          initialResources,
          (this.multiple
            ? (this.multiSelect.selected as MatOption[])
            : []
          )?.map(s => s.value) || [],
        );
      });
  }

  private addMoreResourcesOnScrollEnd(event: any): void {
    if (
      event.target.scrollTop >=
      event.target.scrollHeight - event.target.offsetHeight - 25
    ) {
      this.addMoreResources();
    }
  }

  private addMoreResources() {
    this.pageNumber += 1;
    if (this.maxPageCount < this.pageNumber) {
      this.pageNumber = this.maxPageCount;
      return;
    }

    this.setNotIds(this.filters || {});

    this.datastore
      .findAll(this.className, {
        filter: this.filters,
        page: {
           
          number: this.pageNumber,
          size: this.resultsLimit,
        },
        include: this.includes || undefined,
        sort: this.getSort(),
      })
      .pipe(take(1))
      .subscribe(resources => {
        const newResources = (resources.getModels() as Resource[]).filter(
          r =>
            !this.displayedResources.find(
              (sR: Resource) => sR.id?.toString() === r.id?.toString(),
            ),
        );

        this.displayedResources = this.displayedResources
          .concat(newResources)
          .filter(r => !this.excludeIds.includes(r.id));
        this.filteredServerSideResources.next(this.displayedResources);
      });
  }

  private getInitialSelected(): Observable<JsonApiQueryData<JsonApiModel>> {
    if (this.isMemberClass() || this.className === MemberIdentifier) {
      return this.memberService.getMemberByIds(this.initialIds);
    }
    return this.datastore.findAll(this.className, {
      filter: this.getInitialIdsFilter(),
      include: this.includes || undefined,
      sort: this.getSort(),
    });
  }

  private getInitialIdsFilter(): void {
    const initialIdsFilter: any = {};
    switch (this.className) {
      case Group:
        initialIdsFilter['group_ids'] = this.initialIds;
        break;
      case Member:
      case MemberTrimmed:
        initialIdsFilter['member_ids'] = this.initialIds;
        break;
      case Course:
        initialIdsFilter['course_ids'] = this.initialIds;
        break;
      case CourseCategory:
        initialIdsFilter['category_ids'] = this.initialIds;
        break;
      case ILTCourse:
        initialIdsFilter['ilt_course_ids'] = this.initialIds;
        break;
      case Country:
        initialIdsFilter['country_ids'] = this.initialIds;
        break;
      case LearningPath:
        initialIdsFilter['learning_path_ids'] = this.initialIds;
        break;
      case CourseActivity:
        initialIdsFilter['course_activity_ids'] = this.initialIds;
        break;
      case OrganizationLevel:
        initialIdsFilter['org_ids'] = this.initialIds;
        break;
      case CertificateTemplate:
        initialIdsFilter['id'] = this.initialIds;
        break;
      case TrainingSchedule:
        initialIdsFilter['schedule_ids'] = this.initialIds;
        break;
      case TrainingScheduleCohort:
        initialIdsFilter['cohort_ids'] = this.initialIds;
        break;
      case CustomEmail:
        initialIdsFilter['email_ids'] = this.initialIds;
        break;
      default:
        break;
    }

    if (this.initialFilters) {
      return Object.assign(initialIdsFilter, this.initialFilters);
    }

    return initialIdsFilter;
  }

  private getSort(): string {
    switch (this.className) {
      case Country:
        return 'name';
      case Course:
      case ILTCourse:
      case LearningPath:
      case TrainingSchedule:
        return 'state';
    }
    return '';
  }

  private isMemberClass(): boolean {
    return this.className === Member || this.className === MemberTrimmed;
  }

  private setNotIds(filters: {
    [key: string]: string | boolean | string[] | undefined;
  }): { [key: string]: string | boolean | string[] | undefined } {
    if (this.hideInitialResources && this.initialIds?.length) {
      filters['not_ids'] = this.initialIds;
    }

    return filters;
  }
}
